import React, { useEffect, useState, useContext } from "react";
import { Card, List, Typography, Divider } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import useApiClient from "../../../apiClient";
import { AuthContext } from "../../login/AuthContext";
import "../PeerReview.css";

const { Title, Text } = Typography;

const PeerReviewHome = () => {
  const apiClient = useApiClient();
  const { user } = useContext(AuthContext);
  const userId = user.id;
  const [cardData, setCardData] = useState({
    reviewsReceived: 0,
    pointsAccumulated: 0,
  });
  const [feedbackData, setFeedbackData] = useState({
    reviews: [],
    pagination: {},
  });

  useEffect(() => {
    const fetchCardData = async () => {
      try {
        const response = await apiClient.get(
          `/api/reviews/reviewee/metrics/${userId}`
        );
        setCardData(response.data);
      } catch (error) {
        console.error("Failed to fetch card data", error);
      }
    };

    const fetchFeedbackData = async () => {
      try {
        const response = await apiClient.get(
          `/api/reviews/reviewee/${userId}?page=1&limit=10`
        );

        setFeedbackData(response.data);
      } catch (error) {
        console.error("Failed to fetch feedback data", error);
      }
    };

    fetchCardData();
    fetchFeedbackData();
  }, [userId]);

  return (
    <div>
      <Title level={4} style={{ marginTop: "5px" }}>
        Metrics This Month
      </Title>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          gap: "20px",
          padding: "20px",
        }}
      >
        <Card style={{ width: 300, textAlign: "center", flex: "1 1 300px" }}>
          <div style={{ fontSize: "24px", marginBottom: "20px" }}>
            {cardData.reviewsReceived}
          </div>
          <div>Reviews Received</div>
        </Card>
        <Card style={{ width: 300, textAlign: "center", flex: "1 1 300px" }}>
          <div style={{ fontSize: "24px", marginBottom: "20px" }}>
            {cardData.pointsAccumulated}
          </div>
          <div>Points Accumulated</div>
        </Card>
      </div>

      <div style={{ marginTop: "20px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "5px",
          }}
        >
          <Title level={4} style={{ margin: 0 }}>
            Feedbacks Received
          </Title>
        </div>
        <Divider style={{ marginTop: 0 }} />
        <List
          itemLayout="vertical"
          style={{ width: "100%" }}
          // grid={{ gutter: 16, column: 2 }} // Display in 2 columns
          dataSource={feedbackData.reviews}
          renderItem={(item) => (
            <List.Item style={{ width: "100%" }}>
              <List.Item.Meta
                title={<Text>{item.title}</Text>}
                description={
                  <>
                    <div style={{ marginBottom: "2px" }}>
                      <CalendarOutlined
                        style={{ color: "rgba(0, 0, 0, 0.45)" }}
                      />{" "}
                      {new Date(item.createdOn).toLocaleDateString()}
                    </div>
                    <div>
                      <Text type="primary">{item.description}</Text>
                    </div>
                  </>
                }
              />
            </List.Item>
          )}
        />
      </div>
    </div>
  );
};

export default PeerReviewHome;
