import React from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { Layout, Button, Row, Col, Divider } from "antd";
import EventsHome from "./EventsHome";
import ManageEvents from "./ManageEvents";
import Wrapper from "../common/Wrapper";

const { Content } = Layout;

const Events = () => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Row type="flex" justify="space-between" align="middle">
        <Col>
          <h2>Events</h2>
        </Col>
        <Col>
          <Button type="link" onClick={() => navigate("")}>
            Home
          </Button>
          <Button type="link" onClick={() => navigate("manage")}>
            Manage
          </Button>
        </Col>
      </Row>
      <Divider style={{ margin: 0 }} />
      <Content style={{paddingBottom: "60px"}}>
        <Routes>
          <Route path="manage" element={<ManageEvents />} />
          <Route index element={<EventsHome />} />
        </Routes>
      </Content>
    </Wrapper>
  );
};

export default Events;
