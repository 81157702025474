import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import useApiClient from "../../apiClient";
import { Card, Row, Col } from "antd";

const { Meta } = Card;

const VideoCarousel = () => {
  const apiClient = useApiClient();
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const fetchVideos = async () => {
      const response = await apiClient.get("/api/videos/");
      setVideos(response.data);
    };
    fetchVideos();
  }, [apiClient]);

  return (
    <div style={{ marginTop: "20px" }}>
      <Row gutter={[16, 16]}>
        {videos.map((video) => (
          <Col
            key={video.id}
            xs={24}
            sm={12}
            md={8}
            lg={8}
            xl={6}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Link to={`/videos/${video.id}`}>
              <Card
                id="video-carousel-card"
                hoverable
                cover={
                  <img
                    src={video.thumbnail}
                    alt={video.title}
                    style={{
                      width: "100%",
                      height: "250px",
                      objectFit: "cover",
                      borderRadius: "4px 4px 0 0",
                    }}
                  />
                }
                style={{ height: "340px" }}
              >
                <Meta title={video.title} description={video.description} />
              </Card>
            </Link>
          </Col>
        ))}
      </Row>
      <style jsx="true">{`
        #video-carousel-card .ant-card {
          border-radius: 10px;
          overflow: hidden;
        }

        #video-carousel-card .ant-card-body {
          padding: 12px !important;
        }

        #video-carousel-card .ant-card-meta-title {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 0px !important;
        }

        #video-carousel-card .ant-card-meta-description {
          font-size: 14px;
        }
      `}</style>
    </div>
  );
};

export default VideoCarousel;
