import React, { useState, useEffect } from "react";
import { Button, Alert, Card, Row, Col, Typography } from "antd";
import { RedoOutlined, PlayCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import "./TicTacToe.css";

const { Title } = Typography;

const TicTacToe = () => {
  const [board, setBoard] = useState(Array(9).fill(null));
  const [userSymbol, setUserSymbol] = useState(null);
  const [systemSymbol, setSystemSymbol] = useState(null);
  const [isUserTurn, setIsUserTurn] = useState(null);
  const [gameOver, setGameOver] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);
  const [gameResult, setGameResult] = useState("");

  const checkWinner = (board) => {
    const winPatterns = [
      [0, 1, 2],
      [3, 4, 5],
      [6, 7, 8],
      [0, 3, 6],
      [1, 4, 7],
      [2, 5, 8],
      [0, 4, 8],
      [2, 4, 6],
    ];
    for (let [a, b, c] of winPatterns) {
      if (board[a] && board[a] === board[b] && board[a] === board[c]) {
        return board[a];
      }
    }
    return null;
  };

  const isBoardFull = (board) => board.every((cell) => cell !== null);

  const handleClick = (index) => {
    if (board[index] || gameOver || !isUserTurn || !gameStarted) return;

    const newBoard = board.slice();
    newBoard[index] = userSymbol;
    setBoard(newBoard);
    setIsUserTurn(false);
  };

  const minimax = (board, depth, isMaximizing) => {
    const winner = checkWinner(board);
    if (winner === systemSymbol) return 10 - depth;
    if (winner === userSymbol) return depth - 10;
    if (isBoardFull(board)) return 0;

    if (isMaximizing) {
      let bestScore = -Infinity;
      for (let i = 0; i < board.length; i++) {
        if (!board[i]) {
          board[i] = systemSymbol;
          let score = minimax(board, depth + 1, false);
          board[i] = null;
          bestScore = Math.max(score, bestScore);
        }
      }
      return bestScore;
    } else {
      let bestScore = Infinity;
      for (let i = 0; i < board.length; i++) {
        if (!board[i]) {
          board[i] = userSymbol;
          let score = minimax(board, depth + 1, true);
          board[i] = null;
          bestScore = Math.min(score, bestScore);
        }
      }
      return bestScore;
    }
  };

  const systemMove = () => {
    let bestScore = -Infinity;
    let move;
    for (let i = 0; i < board.length; i++) {
      if (!board[i]) {
        board[i] = systemSymbol;
        let score = minimax(board, 0, false);
        board[i] = null;
        if (score > bestScore) {
          bestScore = score;
          move = i;
        }
      }
    }
    const newBoard = board.slice();
    newBoard[move] = systemSymbol;
    setBoard(newBoard);
    setIsUserTurn(true);
  };

  useEffect(() => {
    if (gameStarted) {
      const winner = checkWinner(board);
      if (winner) {
        setGameOver(true);
        setGameResult(`${winner === userSymbol ? "You win!" : "System wins!"}`);
      } else if (isBoardFull(board)) {
        setGameOver(true);
        setGameResult("It's a draw!");
      } else if (!isUserTurn && !gameOver) {
        setTimeout(systemMove, 500); // .5-second delay before system move
      }
    }
  }, [board, isUserTurn, gameStarted, gameOver]);

  const resetGame = () => {
    setBoard(Array(9).fill(null));
    setGameOver(false);
    setGameResult("");
    setIsUserTurn(null);
    setGameStarted(false);
    setUserSymbol(null);
    setSystemSymbol(null);
  };

  const startGame = () => {
    if (userSymbol === null) {
      alert("Please select X or O to start the game.");
      return;
    }
    setGameStarted(true);
    setGameOver(false);
    setBoard(Array(9).fill(null));
    setIsUserTurn(userSymbol === "X");
    setGameResult("");
  };

  const endGame = () => {
    setGameStarted(false);
    setGameResult("Game ended!");
    resetGame();
  };

  const handleSymbolSelect = (symbol) => {
    if (!gameStarted) {
      setUserSymbol(symbol);
      setSystemSymbol(symbol === "X" ? "O" : "X");
      setIsUserTurn(symbol === "X");
      setGameResult("");
    }
  };

  return (
    <div style={{marginTop: "20px"}}>
      <Title level={4} style={{marginTop: 0}}>Tic Tac Toe</Title>
      {gameResult && (
        <Alert
          message={gameResult}
          type={
            gameResult.includes("win")
              ? "success"
              : gameResult.includes("draw")
              ? "info"
              : gameResult.includes("ended")
              ? "info"
              : "error"
          }
          showIcon
          banner
          style={{ marginBottom: "20px" }}
        />
      )}
      <div style={{ textAlign: "center" }}>
        {/* <h3>Choose Your Symbol</h3> */}
        <Row gutter={16} justify="center">
          <Col>
            <Card
              style={{
                minWidth: 150,
                borderBottom: userSymbol === "X" ? "3px solid #1890ff" : "3px solid #ff4d4f",
                cursor: gameStarted ? "not-allowed" : "pointer",
                opacity: gameStarted ? 0.5 : 1,
              }}
              hoverable={!gameStarted}
              onClick={() => handleSymbolSelect("X")}
            >
              <img
                src="https://www.freebiefindingmom.com/wp-content/uploads/2021/03/free_printable_capital_modern_calligraphy_letters_modern_calligraphy_X.jpg"
                alt="X"
                style={{ width: "60px", height: "60px" }}
              />
              {userSymbol ? (
                <Card.Meta
                  title={userSymbol === "X" ? "You" : "System"}
                  style={{ textAlign: "center", marginTop: "10px" }}
                />
              ): <Card.Meta
              title={"Choose This"}
              style={{ textAlign: "center", marginTop: "10px" }}
            />}
            </Card>
          </Col>
          <Col>
            <Card
              style={{
                minWidth: 150,
                borderBottom: userSymbol === "O" ? "3px solid #1890ff" : "3px solid #ff4d4f",
                cursor: gameStarted ? "not-allowed" : "pointer",
                opacity: gameStarted ? 0.5 : 1,
              }}
              hoverable={!gameStarted}
              onClick={() => handleSymbolSelect("O")}
            >
              <img
                src="https://www.freebiefindingmom.com/wp-content/uploads/2021/03/free_printable_capital_modern_calligraphy_letters_modern_calligraphy_O.jpg"
                alt="O"
                style={{ width: "60px", height: "60px" }}
              />
              {userSymbol ? (
                <Card.Meta
                  title={userSymbol === "O" ? "You" : "System"}
                  style={{ textAlign: "center", marginTop: "10px" }}
                />
              ): <Card.Meta
              title={"Choose This"}
              style={{ textAlign: "center", marginTop: "10px" }}
            />}
            </Card>
          </Col>
        </Row>
        {/* {userSymbol !== null && !gameStarted && (
          <h3>{userSymbol === "X" ? "You play first" : "System plays first"}</h3>
        )} */}
        <div className="board">
          {board.map((cell, index) => (
            <Button
              key={index}
              className="cell"
              onClick={() => handleClick(index)}
              disabled={gameOver || !gameStarted}
              style={{
                backgroundColor:
                  cell === userSymbol
                    ? "#e6f7ff"
                    : cell === systemSymbol
                    ? "#fff1f0"
                    : "white",
              }}
            >
              {cell}
            </Button>
          ))}
        </div>
        {userSymbol !== null && !gameStarted && (
          <p>{userSymbol === "X" ? "You play first, click Start Play to begin" : "System plays first, click Start Play to begin"}</p>
        )}
        {!gameStarted && userSymbol !== null && (
          <Button
            type="primary"
            onClick={startGame}
            style={{ marginTop: "10px", width: "30%" }}
            icon={<PlayCircleOutlined />}
          >
            Start Play
          </Button>
        )}
        {gameStarted && !gameOver && (
          <Button
            danger
            onClick={endGame}
            disabled={gameOver}
            style={{ marginTop: "10px", width: "30%" }}
            icon={<CloseCircleOutlined />}
          >
            End Play
          </Button>
        )}
        {gameOver && (
          <Button
            type="default"
            onClick={resetGame}
            style={{ marginTop: "10px", marginLeft: "10px", width: "30%" }}
            icon={<RedoOutlined />}
          >
            Restart Game
          </Button>
        )}
      </div>
    </div>
  );
};

export default TicTacToe;
