import React from "react";
import { Card, Button, Tag, Typography } from "antd";
import {
  TeamOutlined,
  ClockCircleOutlined,
  TrophyOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import "./Challenges.css";

const { Title, Text } = Typography;

const formatDate = (date) => {
  const options = { day: "2-digit", month: "short", year: "numeric" };
  return new Date(date).toLocaleDateString("en-US", options).replace(",", "");
};

const ChallengeCard = ({ challenge, onEnroll, onWithdraw, showDrawer }) => (
  <Card
    hoverable
    className="challenge-card"
    onClick={() => showDrawer(challenge, false)}
    title={
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Title level={5} style={{ marginBottom: 0, marginTop: 0 }}>
          {challenge.name}
        </Title>
        <Text type="secondary">
          {formatDate(challenge.start_date)} - {formatDate(challenge.end_date)}
        </Text>
      </div>
    }
  >
    <div className="challenge-card-content">
      <div style={{ marginBottom: "8px" }}>
        <Tag color="purple">
          <ClockCircleOutlined style={{ marginRight: "8px" }} />{" "}
          {challenge.duration} {challenge.unit}
        </Tag>
        {
            challenge.enrolled && <Tag color="#2db7f5">
            Enrolled
            </Tag>
        }
      </div>

      {/* Tasks Information */}
      <p>
        <CheckCircleOutlined style={{ marginRight: "8px", color: "#1890ff" }} />{" "}
        {challenge.tasks.length} tasks
      </p>
      <p>
        <TrophyOutlined style={{ marginRight: "8px", color: "green" }} />{" "}
        Complete {challenge.pass_criteria} to win
      </p>
      <p>
        <TeamOutlined style={{ marginRight: "8px", color: "blue" }} />{" "}
        {challenge.total_enrolled} enrolled
      </p>
    </div>
    <Button
      danger={challenge.enrolled}
      ghost={!challenge.enrolled}
      style={{
        width: "100%",
        marginTop: "10px",
        marginBottom: "10px",
        borderColor: !challenge.enrolled ? "#1890ff" : "",
        color: !challenge.enrolled ? "#1890ff" : "",
      }}
      onClick={(e) => {
        e.stopPropagation();
        if (challenge.enrolled) {
          onWithdraw(challenge);
        } else {
          onEnroll(challenge);
        }
      }}
    >
      {challenge.enrolled ? "Withdraw" : "Enroll"}
    </Button>
  </Card>
);

export default ChallengeCard;
