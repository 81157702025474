import React, { useState, useEffect, useRef } from 'react';
import { Button, Layout } from 'antd';
import './SnakeGame.css';

const { Header, Content } = Layout;

const SnakeGame = () => {
  const [snake, setSnake] = useState([[0, 0]]);
  const [direction, setDirection] = useState('RIGHT');
  const [food, setFood] = useState([10, 10]);
  const [isGameRunning, setIsGameRunning] = useState(false);
  const [score, setScore] = useState(0);
  const boardSize = 20;

  const gameInterval = useRef(null);

  const startGame = () => {
    setIsGameRunning(true);
    setSnake([[0, 0]]);
    setDirection('RIGHT');
    setFood(generateFoodPosition());
    setScore(0);

    gameInterval.current = setInterval(moveSnake, 200);
  };

  const stopGame = () => {
    setIsGameRunning(false);
    clearInterval(gameInterval.current);
  };

  const generateFoodPosition = () => {
    let position;
    do {
      const x = Math.floor(Math.random() * boardSize);
      const y = Math.floor(Math.random() * boardSize);
      position = [x, y];
    } while (snake.some((segment) => segment[0] === position[0] && segment[1] === position[1]));
    return position;
  };

  const moveSnake = () => {
    setSnake((prevSnake) => {
      const newSnake = [...prevSnake];
      const head = [...newSnake[newSnake.length - 1]];

      switch (direction) {
        case 'UP':
          head[1] -= 1;
          break;
        case 'DOWN':
          head[1] += 1;
          break;
        case 'LEFT':
          head[0] -= 1;
          break;
        case 'RIGHT':
          head[0] += 1;
          break;
        default:
          break;
      }

      // Check if the snake has collided with the walls or itself
      if (
        head[0] < 0 ||
        head[1] < 0 ||
        head[0] >= boardSize ||
        head[1] >= boardSize ||
        newSnake.some((segment) => segment[0] === head[0] && segment[1] === head[1])
      ) {
        console.log('Collision detected, stopping the game');
        stopGame();
        return prevSnake;
      }

      newSnake.push(head);

      // Check if the snake has eaten the food
      if (head[0] === food[0] && head[1] === food[1]) {
        setFood(generateFoodPosition());
        setScore((prevScore) => prevScore + 1);
        console.log('Food eaten, new score:', score + 1);
      } else {
        newSnake.shift();
      }

      console.log('Snake moved to:', newSnake);
      return newSnake;
    });
  };

  const handleKeyDown = (e) => {
    console.log('Key pressed:', e.key); // Debug log
    switch (e.key) {
      case 'ArrowUp':
        e.preventDefault();
        if (direction !== 'DOWN') setDirection('UP');
        break;
      case 'ArrowDown':
        e.preventDefault();
        if (direction !== 'UP') setDirection('DOWN');
        break;
      case 'ArrowLeft':
        e.preventDefault();
        if (direction !== 'RIGHT') setDirection('LEFT');
        break;
      case 'ArrowRight':
        e.preventDefault();
        if (direction !== 'LEFT') setDirection('RIGHT');
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (isGameRunning) {
      console.log('Game started, adding event listener'); // Debug log
      window.addEventListener('keydown', handleKeyDown);
    } else {
      console.log('Game stopped, removing event listener'); // Debug log
      window.removeEventListener('keydown', handleKeyDown);
    }
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [isGameRunning, direction]);

  return (
    <Layout>
      <Header style={{ textAlign: 'center' }}>
        <h1>Snake Game</h1>
      </Header>
      <Content style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '80vh' }}>
        <div className="game-board">
          {Array.from({ length: boardSize }).map((_, y) =>
            Array.from({ length: boardSize }).map((_, x) => {
              const isSnake = snake.some((segment) => segment[0] === x && segment[1] === y);
              const isFood = food[0] === x && food[1] === y;
              return (
                <div
                  key={`${x}-${y}`}
                  className={`cell ${isSnake ? 'snake' : isFood ? 'food' : ''}`}
                ></div>
              );
            })
          )}
        </div>
        <div className="controls">
          <Button type="primary" onClick={startGame} disabled={isGameRunning}>
            Start Game
          </Button>
          <Button type="danger" onClick={stopGame} disabled={!isGameRunning}>
            Stop Game
          </Button>
          <div className="score">Score: {score}</div>
        </div>
      </Content>
    </Layout>
  );
};

export default SnakeGame;
