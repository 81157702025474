import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import useApiClient from "../../apiClient";
import "./VideoPlayer.css";
import ReactPlayer from "react-player";
import { Spin, Alert } from "antd";

const VideoPlayer = () => {
  const { id } = useParams();
  const apiClient = useApiClient();
  const [videoData, setVideoData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchVideoData = async () => {
      try {
        const response = await apiClient.get(`/api/videos/${id}`);
        if (response.data && response.data.videoUrl) {
          setVideoData(response.data);
        } else {
          setError("Video not found");
        }
        setLoading(false);
      } catch (err) {
        console.error("Error fetching video data:", err);
        setError("Failed to load video");
        setLoading(false);
      }
    };
    fetchVideoData();
  }, [id, apiClient]);

  if (loading) {
    return (
      <div className="video-player-loading-container">
        <Spin tip="Loading video..." />
      </div>
    );
  }

  if (error) {
    return (
      <div className="video-player-error-container">
        <Alert message="Error" description={error} type="error" showIcon />
      </div>
    );
  }

  return (
    <>
      <div className="video-player-container">
        <ReactPlayer
          url={videoData.videoUrl}
          controls={true}
          width="100%"
          height="100%" // Make the height 100% of the container
          playing={true}
          config={{
            file: {
              attributes: {
                preload: "auto",
              },
            },
          }}
        />
      </div>
      <h2 className="video-player-title">{videoData.title}</h2>
      <p className="video-player-description">{videoData.description}</p>
    </>
  );
};

export default VideoPlayer;
