import React, { useEffect, useState, useContext } from "react";
import { Form, Input, Button, notification } from "antd";
import { AuthContext } from "./AuthContext";
import useApiClient from "../../apiClient";
import { useNavigate, useLocation } from "react-router-dom";

const Login = () => {
  const apiClient = useApiClient();
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [step, setStep] = useState(1);
  const { isAuthenticated, login } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { from } = location.state || { from: { pathname: "/" } };

  // New state for additional user info
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [isNewUser, setIsNewUser] = useState(false); // Tracks if the user is new or not

  useEffect(() => {
    if (isAuthenticated) {
      navigate(from); // If already authenticated, navigate to the original destination
    }
  }, [isAuthenticated, navigate, from]);

  const openNotification = (type, message) => {
    notification[type]({
      message: message,
    });
  };

  const sendCode = async () => {
    if (email) {
      try {
        const response = await apiClient.post("/api/auth/send-code", { email });
        openNotification("success", response.data.message);

        if (response.data.isNewUser) {
          setIsNewUser(true); // If email does not exist, show additional fields
        }
        setStep(2);
      } catch (error) {
        openNotification(
          "error",
          error.response.data.error || "Failed to send code"
        );
      }
    } else {
      openNotification("error", "Please enter a valid email");
    }
  };

  const verifyCode = async () => {
    if (code) {
      try {
        // If new user, include the additional info in the request
        const userDetails = isNewUser
          ? { email, code, firstName, lastName, contactNumber }
          // ? { email, code, firstName, lastName }
          : { email, code };

        const response = await apiClient.post(
          "/api/auth/verify-code",
          userDetails
        );
        const { token, user } = response.data;
        login({ ...user, token }); // Save the token and user details in the context
        navigate(from); // Navigate to the original destination after login
      } catch (error) {
        openNotification("error", error.response.data.error || "Invalid code");
      }
    } else {
      openNotification("error", "Please enter the code");
    }
  };

  return (
    <div style={{ maxWidth: "400px", margin: "0 auto", paddingTop: "100px" }}>
      <Form layout="vertical">
        {step === 1 && (
          <>
            <Form.Item label="Email" required>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" onClick={sendCode} block>
                Send Code
              </Button>
            </Form.Item>
          </>
        )}
        {step === 2 && (
          <>
            {/* Conditionally show additional fields if the user is new */}
            {isNewUser && (
              <>
                <Form.Item label="First Name" required>
                  <Input
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    placeholder="Enter your first name"
                  />
                </Form.Item>
                <Form.Item label="Last Name" required>
                  <Input
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    placeholder="Enter your last name"
                  />
                </Form.Item>
                {<Form.Item label="Contact Number" required>
                  <Input
                    value={contactNumber}
                    onChange={(e) => setContactNumber(e.target.value)}
                    placeholder="Enter your contact number"
                  />
                </Form.Item>}
              </>
            )}

            <Form.Item label="Enter 6-digit Code" required>
              <Input
                type="text"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                placeholder="Enter the code"
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" onClick={verifyCode} block>
                Verify Code
              </Button>
            </Form.Item>
          </>
        )}
      </Form>
    </div>
  );
};

export default Login;
