import React, { useState, useEffect, useContext } from "react";
import {
  Drawer,
  Button,
  Form,
  Input,
  List,
  Avatar,
  message,
  Empty,
  Typography,
} from "antd";
import { AuthContext } from "../login/AuthContext";
import useApiClient from "../../apiClient";
import { getLocalTimeFromUTC4 } from "../../utils/DateUtils";

const { TextArea } = Input;
const { Text } = Typography;

const PostCommentsDrawer = ({
  visible,
  onClose,
  comments = [],
  postId,
  title,
}) => {
  const apiClient = useApiClient(); // Custom API client hook
  const { user } = useContext(AuthContext); // Get user context
  const [newComment, setNewComment] = useState("");
  const [commentsList, setCommentsList] = useState(comments); // Initialize commentsList

  // Sync commentsList with comments prop when it changes
  useEffect(() => {
    setCommentsList(comments);
  }, [comments]);

  const handleCommentChange = (e) => {
    setNewComment(e.target.value);
  };

  const getInitials = (name) => {
    if (!name) return ""; // If the name is undefined or null, return an empty string
    const nameParts = name.split(" ");
    if (nameParts.length >= 2) {
      return `${nameParts[0][0]}${nameParts[1][0]}`.toUpperCase();
    }
    return name[0] ? name[0].toUpperCase() : ""; // Return the first letter, if it exists
  };

  const handlePostComment = async () => {
    const userId = user.id;
    const userName = `${user.first_name} ${user.last_name}`;

    try {
      const response = await apiClient.post("/api/post/comment", {
        postId,
        userId,
        userName,
        comment: newComment,
      });

      if (response.status === 201) {
        const newCommentData = response.data;
        message.success("Comment posted successfully!", 2);
        setNewComment("");

        setCommentsList((prevComments) => [newCommentData, ...prevComments]); // Add new comment at the top
      } else {
        message.error("Failed to post comment.", 2);
      }
    } catch (error) {
      console.error("Error posting comment:", error);
      message.error("An error occurred while posting the comment.", 2);
    }
  };

  return (
    <Drawer
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span>{title}</span>
          <Button type="link" onClick={onClose}>
            Close
          </Button>
        </div>
      }
      placement="right"
      open={visible}
      width={400}
      onClose={onClose}
      closable={false}
      footer={
        <Form>
          <Form.Item>
            <TextArea
              rows={3}
              value={newComment}
              onChange={handleCommentChange}
              placeholder="Write your comment here..."
            />
          </Form.Item>
          <Form.Item style={{ textAlign: "center" }}>
            <Button
              type="primary"
              onClick={handlePostComment}
              disabled={!newComment.trim()}
              style={{ width: "100%" }}
            >
              Post Comment
            </Button>
          </Form.Item>
        </Form>
      }
    >
      <div>
        {commentsList && commentsList.length > 0 ? (
          <List
            itemLayout="horizontal"
            dataSource={commentsList}
            renderItem={(comment) => (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <Avatar
                      style={{
                        backgroundColor: "#888",
                        verticalAlign: "middle",
                      }}
                    >
                      {getInitials(comment.userName)}
                    </Avatar>
                  }
                  title={
                    <Text
                      style={{
                        marginBottom: 0,
                        display: "block",
                        fontWeight: 500,
                      }}
                    >
                      {comment.userName}
                    </Text>
                  }
                  description={
                    <>
                      <Text
                        type="secondary"
                        style={{
                          fontSize: "12px",
                          marginBottom: 10,
                          marginTop: 0,
                          display: "block",
                        }}
                      >
                        {getLocalTimeFromUTC4(comment.createdOn)}
                      </Text>
                      <Text style={{ marginTop: 0 }}>{comment.comment}</Text>
                    </>
                  }
                />
              </List.Item>
            )}
          />
        ) : (
          <Empty description="No Comments" />
        )}
      </div>
    </Drawer>
  );
};

export default PostCommentsDrawer;
