import React, { useState, useEffect, useContext } from "react";
import useApiClient from "../../apiClient";
import EventCard from "./EventCard";
import { AuthContext } from "../login/AuthContext";
import { Spin, Alert, Button, Typography, Row, Col } from "antd";
import "./Events.css";

const { Title } = Typography;

const EventsHome = () => {
  const apiClient = useApiClient();
  const { user } = useContext(AuthContext);
  const [eventDetailsList, setEventDetailsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalEvents, setTotalEvents] = useState(0);

  useEffect(() => {
    const fetchEvents = async () => {
      setLoading(true);
      try {
        const response = await apiClient.get(
          `/api/events?page=${currentPage}&limit=10`
        );
        setEventDetailsList(response.data.events);
        setTotalPages(response.data.pagination.totalPages);
        setTotalEvents(response.data.pagination.totalEvents);
      } catch (err) {
        setError("Failed to load events. Please try again later.");
      }
      setLoading(false);
    };

    fetchEvents();
  }, [currentPage]);

  if (error) {
    return (
      <Alert
        message="Error"
        description={error}
        type="error"
        showIcon
        style={{ margin: "50px auto", maxWidth: 600 }}
      />
    );
  }

  return (
    <Spin
      spinning={loading}
      size="large"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div className="events-app-container">
        <div className="pagination-controls" style={{marginTop: "20px"}}>
          <Title level={5} style={{ marginTop: "5px" }}>
            {totalEvents} Upcoming Events
          </Title>
          <div>
            <Button
              onClick={() =>
                setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
              }
              disabled={currentPage === 1}
            >
              &lt;
            </Button>
            <Button
              onClick={() =>
                setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))
              }
              disabled={currentPage === totalPages}
            >
              &gt;
            </Button>
          </div>
        </div>

        <Row gutter={[16, 16]}>
          {eventDetailsList.map((event, index) => (
            <Col
              key={index}
              xs={24}
              sm={12}
              md={12}
              lg={6}
              xl={6}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <EventCard key={index} event={event} user={user} />
            </Col>
          ))}
        </Row>
      </div>
    </Spin>
  );
};

export default EventsHome;
