import React from "react";
import { Card, Tag, Popconfirm } from "antd";
import {
  EnvironmentOutlined,
  LaptopOutlined,
  TeamOutlined,
  DollarOutlined,
  LinkOutlined,
  UserOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import "./Events.css";

const ManageEventCard = ({ event = {}, onEdit, onRemove }) => {
  // Provide default values to avoid destructuring errors
  const {
    name = "Unnamed Event",
    date = "",
    time = "",
    location = "",
    description = "",
    type = "Virtual",
    attendeeLimit = "No Limit",
    price = "Free",
    fee = 0,
    joinLink = "",
    totalJoined = -1,
  } = event;

  const isEventFull =
    attendeeLimit &&
    attendeeLimit !== "No Limit" &&
    totalJoined >= attendeeLimit;

  // Determine the tags based on the event type and price
  const eventTypeTag =
    type === "Virtual" ? (
      <Tag color="blue">Virtual</Tag>
    ) : type === "In-Person" ? (
      <Tag color="green">In-Person</Tag>
    ) : (
      <Tag color="purple">Hybrid</Tag>
    );

  const priceTag =
    price === "Free" ? (
      <Tag color="#108ee9">Free</Tag>
    ) : price === "Paid" ? (
      <Tag color="red">Paid</Tag>
    ) : (
      <Tag color="orange">Split</Tag>
    );

  const eventFullTag = isEventFull ? <Tag color="#f50">Event Full</Tag> : null;

  return (
    <Card
      title={
        <div>
          <div>{name}</div>
          <div style={{ color: "gray", fontSize: "small" }}>
            {date} | {time}
          </div>
          {type === "In-Person" && (
            <div
              style={{ color: "#1E3A8A", fontSize: "small", fontWeight: "300" }}
            >
              <EnvironmentOutlined style={{ color: "green" }} /> {location}
            </div>
          )}
          {type === "Virtual" && (
            <div
              style={{ color: "#1E3A8A", fontSize: "small", fontWeight: "300" }}
            >
              <LaptopOutlined style={{ color: "blue" }} /> Online
            </div>
          )}
          {type === "Hybrid" && (
            <div
              style={{ color: "#1E3A8A", fontSize: "small", fontWeight: "300" }}
            >
              <EnvironmentOutlined style={{ color: "green" }} /> {location} |{" "}
              <LaptopOutlined style={{ color: "blue" }} /> Online
            </div>
          )}
        </div>
      }
      actions={[
        <Popconfirm
          title="Confirmation Needed"
          description="Are you sure you want to delete this event?"
          onConfirm={onRemove}
          okText="Yes"
          cancelText="No"
        >
          <DeleteOutlined key="delete" style={{ color: "red" }} />
        </Popconfirm>,

        <EditOutlined key="edit" onClick={onEdit} />,
      ]}
      className="event-card"
      hoverable={false}
    >
      <div className="event-card-content">
        <div className="tags">
          {eventTypeTag}
          {priceTag}
          {eventFullTag}
        </div>
        <p className="description">{description}</p>
        {type !== "In-Person" && joinLink && (
          <p>
            <LinkOutlined />{" "}
            <a href={joinLink} target="_blank" rel="noopener noreferrer">
              Join Online
            </a>
          </p>
        )}
        {(price === "Paid" || price === "Split") && (
          <p>
            <DollarOutlined /> ${fee}
          </p>
        )}
        <div className="card-event-people-metrics">
          <p>
            <TeamOutlined />{" "}
            {attendeeLimit ? `${attendeeLimit} Limit` : "No Limit"}
          </p>
          <p>
            {totalJoined > -1 && (
              <>
                <UserOutlined /> {totalJoined} Joined
              </>
            )}
          </p>
        </div>
      </div>
    </Card>
  );
};

export default ManageEventCard;
