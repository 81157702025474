import { useMemo, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthContext } from './components/login/AuthContext';

// Custom hook to set up Axios instance with interceptors
const useApiClient = () => {
  const navigate = useNavigate();
  const { logout } = useContext(AuthContext);

  // Use useMemo to memoize the apiClient instance
  const apiClient = useMemo(() => {
    console.log("Initializing Axios client with baseURL:", process.env.REACT_APP_BACKEND_URL);

    // Create an Axios instance
    const client = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_URL, // Ensure you're using REACT_APP_ prefix for env variables in React
      headers: {
        "Content-Type": "application/json",
      },
    });

    // Add a request interceptor to include the token
    client.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem("token");
        console.log("Request made to:", config.url, "with method:", config.method);
        if (token) {
          console.log("Attaching token to the headers:", token);
          config.headers["Authorization"] = `Bearer ${token}`;
        } else {
          console.log("No token found, proceeding without Authorization header.");
        }
        return config;
      },
      (error) => {
        console.error("Error in request interceptor:", error);
        return Promise.reject(error);
      }
    );

    // Add a response interceptor to handle 403 errors
    client.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && (error.response.status === 403 || error.response.status === 401)) {
          console.log("Token expired or invalid, logging out...");
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          logout();
        }
        return Promise.reject(error);
      }
    );

    return client;
  }, [navigate]);

  return apiClient;
};

export default useApiClient;

