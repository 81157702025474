// MoodTracker.js
import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Modal,
  Tabs,
  Input,
  List,
  Tag,
  Space,
  Typography,
  message,
} from "antd";
import {
  FrownOutlined,
  MehOutlined,
  SmileOutlined,
  HeartOutlined,
  CheckCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import useApiClient from "../../apiClient";
import _ from "lodash";
import { AuthContext } from "../login/AuthContext";
import "./mood.css";
import { formatDate, getUserLocalDateTime } from "../../utils/DateUtils";

const { TabPane } = Tabs;
const { TextArea } = Input;
const { Title } = Typography;

const moodIcons = [
  { icon: "😢", text: "Sad" },
  { icon: "😐", text: "Neutral" },
  { icon: "😊", text: "Happy" },
  { icon: "😁", text: "Very Happy" },
  { icon: "🤩", text: "Ecstatic" },
];

const getMoodColor = (moodText) => {
  switch (moodText.toLowerCase()) {
    case "sad":
      return "red";
    case "neutral":
      return "orange";
    case "happy":
      return "green";
    case "very happy":
      return "blue";
    case "ecstatic":
      return "geekblue";
    default:
      return "gray"; // Default color for unknown moods
  }
};

const MoodTracker = ({ isModalVisible, hideModal }) => {
  const apiClient = useApiClient();
  const { user } = useContext(AuthContext);
  const [selectedMood, setSelectedMood] = useState(-1);
  const [description, setDescription] = useState("");
  const [moodHistory, setMoodHistory] = useState([]);
  const [activeTabKey, setActiveTabKey] = useState("1");

  useEffect(() => {
    if (isModalVisible) {
      // Get the user's local timezone
      const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      apiClient
        .get(`/api/mood/${user.id}`, { params: { timezone: userTimezone } }) // Pass user's timezone to the server
        .then((resp) => {
          if (resp.data) {
            _.forEach(resp.data, (dateMood) => {
              _.forEach(dateMood.moods, (moodLog) => {
                // Map the mood text to the respective moodIcon object
                moodLog.mood = _.find(moodIcons, { text: moodLog.mood });
              });
            });
            setMoodHistory(resp.data); // Update state with the processed mood history
          }
        })
        .catch((e) => {
          message.error("Something went wrong while fetching your data");
        });
    }
  }, [isModalVisible]);

  const handleOk = () => {
    if (selectedMood == -1) {
      return message.error("Please select a mood!");
    }
    // Get user's local date and time in ISO format
    const userLocalDateTime = getUserLocalDateTime();

    // Get user's local timezone
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    let param = {
      userId: user.id,
      mood: moodIcons[selectedMood].text,
      description: description,
      userLocalDateTime: userLocalDateTime, // User's local date and time
      userTimezone: userTimezone, // User's local timezone
    };
    apiClient
      .post("/api/mood/log", param)
      .then((resp) => {
        message.info("You moode has been logged");
        setSelectedMood(null);
        setDescription("");
        hideModal();
      })
      .catch((e) => {
        if (e.response && e.response.data) {
          message.warning(e.response.data.error);
        } else {
          message.error("Error occurred while logging your mood");
        }
      });
  };

  const handleTabChange = (key) => {
    setActiveTabKey(key);
  };

  return (
    <Modal
      title={
        <Tabs
          activeKey={activeTabKey}
          onChange={handleTabChange}
          className="modal-tabs"
        >
          <TabPane tab="Log Your Mood" key="1" />
          <TabPane tab="Mood History" key="2" />
        </Tabs>
      }
      open={isModalVisible}
      onOk={handleOk}
      onCancel={hideModal}
      footer={[
        <Button key="back" onClick={hideModal}>
          Close
        </Button>,
        <Button key="submit" type="primary" onClick={handleOk}>
          Submit
        </Button>,
      ]}
      className="custom-modal"
      centered
    >
      <div className="tab-content">
        {activeTabKey === "1" ? (
          <div className="tab-content-inner">
            <Title level={5}>How are you feeling today?</Title>
            <Space size="large">
              {moodIcons.map((mood, index) => (
                <div
                  key={index}
                  style={{
                    textAlign: "center",
                    cursor: "pointer",
                    color: selectedMood === index ? "#1890ff" : "inherit",
                  }}
                  onClick={() => setSelectedMood(index)}
                >
                  {mood.icon}
                  <div>{mood.text}</div>
                </div>
              ))}
            </Space>
            <Title level={5} style={{ marginTop: "20px" }}>
              Describe how you are feeling (optional)
            </Title>
            <TextArea
              rows={5}
              placeholder="Describe your feelings here..."
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              style={{ marginTop: 10 }}
            />
          </div>
        ) : (
          <div className="tab-content-inner">
            <List
              bordered={false}
              dataSource={moodHistory}
              renderItem={(dateMood) =>
                dateMood.moods.length === 0 ? (
                  <List.Item>
                    <List.Item.Meta
                      avatar={<MinusCircleOutlined />}
                      title={
                        <>
                          {formatDate(dateMood.date)}
                          <br />
                          <Tag color={"gray"} style={{ marginTop: 5 }}>
                            No Mood Logged
                          </Tag>
                        </>
                      }
                    />
                  </List.Item>
                ) : (
                  dateMood.moods.map((item, index) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={item.mood.icon}
                        title={
                          <>
                            {formatDate(item.moodOn)}
                            <br />
                            <Tag
                              color={getMoodColor(item.mood.text)}
                              style={{ marginTop: 5 }}
                            >
                              {item.mood.text}
                            </Tag>
                          </>
                        }
                        description={
                          item.description ? (
                            <>
                              <p style={{ marginTop: 10, marginBottom: 0 }}>
                                {item.description}
                              </p>
                            </>
                          ) : null
                        }
                      />
                    </List.Item>
                  ))
                )
              }
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default MoodTracker;
