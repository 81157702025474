import React from "react";
import "./Wrapper.css";

const Wrapper = ({ children }) => {
  return (
    <div className="wrapper-container">
      <div className="wrapper-header">

      </div>
      {children}
    </div>
  );
};

export default Wrapper;