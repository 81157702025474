import {
  Drawer,
  Form,
  Input,
  InputNumber,
  Button,
  Upload,
  message,
  Spin,
  Divider,
  Row,
  Col,
} from "antd";
import { v4 as uuidv4 } from 'uuid';
import { UploadOutlined } from "@ant-design/icons";
import { useState, useEffect, useContext } from "react";
import { AuthContext } from "../login/AuthContext";
import useApiClient from "../../apiClient";

const { TextArea } = Input;

const ListingDrawer = ({ visible, onClose, listing, fetchListings }) => {
  const apiClient = useApiClient();
  const { user } = useContext(AuthContext);
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);

  // Initialize form and fileList when editing a listing
  useEffect(() => {
    if (listing) {
      form.setFieldsValue(listing);
      const initialFileList = (listing.pics || []).map((url, index) => ({
        uid: index,
        name: `image-${index + 1}`,
        status: "done",
        url,
      }));
      setFileList(initialFileList);
    } else {
      form.resetFields();
      setFileList([]);
    }
  }, [listing, form]);

  const handleFormSubmit = async (values) => {
    setLoading(true);
    const formData = new FormData();

    formData.append("userId", user.id);
    formData.append("contactNumber", "5128898879"); //TODO: bring this from backend
    formData.append("email", user.email);
    formData.append("title", values.title);
    formData.append("description", values.description);
    formData.append("price", values.price);
    formData.append("category", values.category);

    if (listing) {
      formData.append("id", listing.id);
    }

    // Separate existing images and new files
    const existingImages = fileList
      .filter((file) => !file.originFileObj) // Existing files are URLs
      .map((file) => file.url); // Extract URL from each existing image

    // Append existing image URLs to the formData
    existingImages.forEach((url) => {
      formData.append("existingImages", url); // Make sure the field name is consistent
    });

    // Append new files to the formData with renamed file names
    fileList
      .filter((file) => file.originFileObj) // New files are file objects
      .forEach((file) => {
        // Generate a new unique filename using uuid
        const newFileName = `${uuidv4()}${file.name.substring(
          file.name.lastIndexOf(".")
        )}`; // Preserve file extension
        const renamedFile = new File([file.originFileObj], newFileName, {
          type: file.type,
        });

        formData.append("pics", renamedFile); // Send renamed file to backend
      });

    try {
      const response = await apiClient.post("/api/marketplace/save", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 201) {
        message.success("Listing saved successfully", 2);
        fetchListings();
        onClose();
      } else {
        message.error("Failed to save the listing", 2);
      }
    } catch (error) {
      console.error("Error saving listing:", error);
      message.error("An error occurred while saving the listing", 2);
    } finally {
      setLoading(false);
    }
  };

  const handleImageUpload = ({ fileList }) => {
    setFileList(fileList);
  };

  const handleImageRemove = (file) => {
    setFileList(fileList.filter((f) => f.uid !== file.uid));
  };

  return (
    <Drawer
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <span>{listing ? "Edit Listing" : "Create Listing"}</span>
          <Button type="text" onClick={onClose}>
            Close
          </Button>
        </div>
      }
      placement="right"
      open={visible}
      width={400}
      closable={false}
    >
      <Spin spinning={loading}>
        <Form
          className="listings-custom-form"
          form={form}
          layout="vertical"
          onFinish={handleFormSubmit}
          initialValues={{
            title: "",
            description: "",
            price: 0,
            category: "",
          }}
        >
          <Form.Item
            name="title"
            label="Title"
            rules={[{ required: true, message: "Please enter the title" }]}
          >
            <Input placeholder="Enter title" disabled={listing} />
          </Form.Item>

          <Form.Item
            name="description"
            label="Description"
            rules={[
              { required: true, message: "Please enter the description" },
            ]}
          >
            <TextArea rows={4} placeholder="Enter description" />
          </Form.Item>

          <Form.Item
            name="price"
            label="Price"
            rules={[{ required: true, message: "Please enter the price" }]}
          >
            <InputNumber
              placeholder="Enter price"
              style={{ width: "100%" }}
              min={0}
            />
          </Form.Item>

          <Form.Item
            name="category"
            label="Category"
            rules={[{ required: true, message: "Please enter the category" }]}
          >
            <Input placeholder="Enter category" />
          </Form.Item>

          <Form.Item label="Images">
            <Upload
              listType="picture-card"
              fileList={fileList}
              onChange={handleImageUpload}
              onRemove={handleImageRemove}
              beforeUpload={() => false}
              accept="image/*"
              multiple
            >
              {fileList.length >= 8 ? null : (
                <div>
                  <UploadOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              )}
            </Upload>
          </Form.Item>

          <Divider />

          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
              {listing ? "Update Listing" : "Create Listing"}
            </Button>
          </Form.Item>
        </Form>
        {/* Custom CSS to remove asterisk for required fields */}
        <style jsx="true">{`
          .listings-custom-form .ant-form-item-required::before {
            display: none !important;
          }
        `}</style>
      </Spin>
    </Drawer>
  );
};

export default ListingDrawer;
