import React, { useState, useContext, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./components/login/AuthContext";
import ProtectedRoute from "./components/login/ProtectedRoute";
import Home from "./components/home/Home";
import Challenges from "./components/challenges/Challenges";
import Articles from "./components/articles/Articles";
import Tips from "./components/tips/Tips";
import Events from "./components/events/Events";
import PeerReview from "./components/peerReview/PeerReview";
import Subnav from "./components/subnav/subnav";
import MoodTracker from "./components/mood/mood";
import Games from "./components/games/Games";
import Marketplace from "./components/marketplace/Marketplace";
import Login from "./components/login/Login";
import Poll from "./components/poll/Poll";
import Videos from "./components/videos/Videos";
import AudioPlayer from "./components/audio/AudioPlayer";

const App = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAudioPlayerVisible, setIsAudioPlayerVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const hideModal = () => {
    setIsModalVisible(false);
  };

  const showMusicPlayer = () => {
    setIsAudioPlayerVisible(true);
  };

  return (
    <AuthProvider>
      <Router>
        <Subnav showMoodModal={showModal} showMusicPlayer={showMusicPlayer} />
        <div>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              }
            />
            <Route
              path="/challenges"
              element={
                <ProtectedRoute>
                  <Challenges />
                </ProtectedRoute>
              }
            />
            <Route
              path="/articles"
              element={
                <ProtectedRoute>
                  <Articles />
                </ProtectedRoute>
              }
            />
            <Route
              path="/marketplace/*"
              element={
                <ProtectedRoute>
                  <Marketplace />
                </ProtectedRoute>
              }
            />
            {/* <Route
              path="/tips"
              element={
                <ProtectedRoute>
                  <Tips />
                </ProtectedRoute>
              }
            /> */}
            <Route
              path="/events/*"
              element={
                <ProtectedRoute>
                  <Events />
                </ProtectedRoute>
              }
            />
            <Route
              path="/peer-review/*"
              element={
                <ProtectedRoute>
                  <PeerReview />
                </ProtectedRoute>
              }
            />
            <Route
              path="/mood"
              element={
                <ProtectedRoute>
                  <MoodTracker />
                </ProtectedRoute>
              }
            />
            <Route
              path="/poll"
              element={
                <ProtectedRoute>
                  <Poll />
                </ProtectedRoute>
              }
            />
            <Route
              path="/videos/*"
              element={
                <ProtectedRoute>
                  <Videos />
                </ProtectedRoute>
              }
            />
            <Route
              path="/games/*"
              element={
                <ProtectedRoute>
                  <Games />
                </ProtectedRoute>
              }
            />
          </Routes>
        </div>
        <MoodTracker isModalVisible={isModalVisible} hideModal={hideModal} />
        <AudioPlayer isAudioPlayerVisible={isAudioPlayerVisible} onClose={() => setIsAudioPlayerVisible(false)} />
      </Router>
    </AuthProvider>
  );
};

export default App;
