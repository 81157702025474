import React from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { Layout, Button, Row, Col, Divider } from "antd";
import GamesHome from "./GamesHome";
import TicTacToe from "./tic-tac-toe/TicTacToe";
import SnakeGame from "./snake-game/SnakeGame";
import Game2048 from "./2048/Game2048";
import SudokuBoard from "./sudoku/Sudoku";
import NumberGuessingGame from "./numer-guessing/NumberGuessingGame";
import Wrapper from "../common/Wrapper";

const { Content } = Layout;

const games = [
  {
    title: "Tic-Tac-Toe",
    image:
      "https://static.vecteezy.com/system/resources/previews/022/310/928/original/tic-tac-toe-board-game-3d-illustration-png.png",
    description: "A classic two-player game of Xs and Os.",
    path: "tic-tac-toe",
  },
  {
    title: "Memory Card Game",
    image:
      "http://shine365.marshfieldclinic.org/wp-content/uploads/2016/07/Memory-Loss_I.png",
    description: "Find matching pairs of cards.",
  },
  {
    title: "Number Guessing Game",
    image:
      "https://www.printablee.com/postpic/2014/10/free-number-printables_286525.jpg",
    description: "Guess the number in the range.",
    path: "number-guessing",
  },
  {
    title: "Sudoku",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/d/d3/Sudoku_Puzzle_%28a_puzzle_with_total_symmetry%29_trimmed.png",
    description: "Sudoku Game",
    path: "sudoku",
  },
  {
    title: "Rock, Paper, Scissors",
    image: "https://via.placeholder.com/150?text=Rock+Paper+Scissors",
    description: "Classic hand game against the computer.",
  },
  {
    title: "Simon Says",
    image: "https://via.placeholder.com/150?text=Simon+Says",
    description: "Repeat the sequence of colors.",
  },
  {
    title: "2048 Game",
    image: "https://gabrielecirulli.github.io/2048/meta/og_image.png",
    description: "Combine tiles to reach 2048.",
    path: "2048-game",
  },
  {
    title: "Hangman",
    image: "https://via.placeholder.com/150?text=Hangman",
    description: "Guess letters to reveal the hidden word.",
  },
];

const Games = () => {
  const navigate = useNavigate();

  const handleNavigateToGame = (path) => {
    navigate(`/games/${path}`);
  };

  return (
    <Wrapper>
      <Row type="flex" justify="space-between" align="middle">
        <Col>
          <h2>Games</h2>
        </Col>
        <Col>
          <Button type="link" onClick={() => navigate("")}>
            Home
          </Button>
          <Button type="link" onClick={() => navigate("leaderboard")}>
            Leaderboard
          </Button>
        </Col>
      </Row>
      <Divider style={{ margin: 0 }} />
      <Content style={{paddingBottom: "60px"}}>
        <Routes>
          {/* <Route path="leaderboard" element={<CreateEventForm />} /> */}
          <Route
            path="/"
            element={
              <GamesHome games={games} onGameClick={handleNavigateToGame} />
            }
          />
          <Route path="tic-tac-toe" element={<TicTacToe />} />
          <Route path="snake-game" element={<SnakeGame />} />
          <Route path="2048-game" element={<Game2048 />} />
          <Route path="sudoku" element={<SudokuBoard />} />
          <Route path="number-guessing" element={<NumberGuessingGame />} />
        </Routes>
      </Content>
    </Wrapper>
  );
};

export default Games;
