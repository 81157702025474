import React, { useEffect, useState, useContext } from "react";
import { Card, List, Drawer, Typography, Button, Divider } from "antd";
import useApiClient from "../../../apiClient";
import ProvideFeedback from "./ProvideFeedback";
import { AuthContext } from "../../login/AuthContext";
import { CalendarOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

const AppreciatePeer = () => {
  const apiClient = useApiClient();
  const { user } = useContext(AuthContext);
  const userId = user.id;
  const [cardData, setCardData] = useState([]);
  const [feedbackData, setFeedbackData] = useState({
    reviews: [],
    pagination: {},
  });
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const fetchMetricsAndFeedbacksWritten = () => {
    fetchData();
    fetchFeedbacksWrittenData();
    closeDrawer(true);
  };

  const fetchData = async () => {
    try {
      const response = await apiClient.get(
        `/api/reviews/reviewer/metrics/${userId}`
      );
      const { reviewsWritten, pointsAwarded, pointsBalance } = response.data;
      const formattedData = [
        { number: reviewsWritten, text: "Reviews Written" },
        { number: pointsAwarded, text: "Points Awarded" },
        { number: pointsBalance, text: "Points Remaining" },
      ];
      setCardData(formattedData);
    } catch (error) {
      console.error("Error fetching card data:", error);
    }
  };

  const fetchFeedbacksWrittenData = async () => {
    try {
      const response = await apiClient.get(
        `/api/reviews/reviewer/${userId}?page=1&limit=10`
      );
      setFeedbackData(response.data);
    } catch (error) {
      console.error("Failed to fetch feedbacks written", error);
    }
  };

  const showDrawer = () => {
    setIsDrawerVisible(true);
  };

  const closeDrawer = () => {
    setIsDrawerVisible(false);
  };

  useEffect(() => {
    fetchData();
    fetchFeedbacksWrittenData();
  }, [userId]);

  return (
    <>
      <Title level={4} style={{ marginTop: "5px" }}>
        Metrics This Month
      </Title>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          gap: "20px",
          padding: "20px",
        }}
      >
        {cardData.map((card, index) => (
          <Card
            key={index}
            style={{ width: 300, textAlign: "center", flex: "1 1 300px" }}
          >
            <div style={{ fontSize: "24px", marginBottom: "20px" }}>
              {card.number}
            </div>
            <div>{card.text}</div>
          </Card>
        ))}
      </div>

      <div style={{ marginTop: "20px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "5px",
          }}
        >
          <Title level={4} style={{ margin: 0 }}>
            Feedbacks Written
          </Title>
          <Button type="link" style={{ padding: 0 }} onClick={showDrawer}>
            Submit Feedback
          </Button>
        </div>
        <Divider style={{ marginTop: 0 }} />
        <List
          itemLayout="vertical"
          style={{ width: "100%" }}
          dataSource={feedbackData.reviews}
          renderItem={(item) => (
            <List.Item style={{ width: "100%" }}>
              <List.Item.Meta
                title={<Text>{item.title}</Text>}
                description={
                  <>
                    <div style={{ marginBottom: "2px" }}>
                      <CalendarOutlined
                        style={{ color: "rgba(0, 0, 0, 0.45)" }}
                      />{" "}
                      {new Date(item.createdOn).toLocaleDateString()}
                    </div>
                    <div>
                      <Text type="primary">{item.description}</Text>
                    </div>
                  </>
                }
              />
            </List.Item>
          )}
        />
      </div>

      <Drawer
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <span>Submit Feedback</span> {/* Title on the left */}
            <Button type="text" onClick={() => closeDrawer(false)}>
              Close
            </Button>{" "}
            {/* Close button on the right */}
          </div>
        }
        placement="right"
        closable={false}
        open={isDrawerVisible}
        style={{ paddingTop: 0 }}
        onClose={closeDrawer}
      >
        <ProvideFeedback onFormSubmitSuccess={fetchMetricsAndFeedbacksWritten} reviewerId={userId} closeDrawer={closeDrawer} />
      </Drawer>
    </>
  );
};

export default AppreciatePeer;
