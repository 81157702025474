import React from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { Layout, Button, Row, Col, Divider } from "antd";
import VideoCarousel from "./VideoCarousel";
import VideoPlayer from "./VideoPlayer";
import Wrapper from "../common/Wrapper";

const { Content } = Layout;

const Videos = () => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Row type="flex" justify="space-between" align="middle">
        <Col>
          <h2>Yoga Videos</h2>
        </Col>
        <Col>
          <Button type="link" onClick={() => navigate("")}>
            Home
          </Button>
        </Col>
      </Row>
      <Divider style={{ margin: 0 }} />
      <Content style={{paddingBottom: "60px"}}>
        <Routes>
          <Route path=":id" element={<VideoPlayer />} />
          <Route index element={<VideoCarousel />} />
        </Routes>
      </Content>
    </Wrapper>
  );
};

export default Videos;
