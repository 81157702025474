import React, { useState, useEffect, useContext } from "react";
import { message, Divider, Typography, Space } from "antd";
import _ from "lodash";
import OngoingChallenge from "./OngoingChallenge";
import UpcomingChallenges from "./UpcomingChallenges";
import Tasks from "./Tasks";
import { AuthContext } from "../login/AuthContext";
import useApiClient from "../../apiClient";
import "./Challenges.css";
import Wrapper from "../common/Wrapper";

const { Title } = Typography;

const Challenges = () => {
  const apiClient = useApiClient();
  const [selectedChallenge, setSelectedChallenge] = useState(null);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [upcomingChallenges, setUpcomingChallenges] = useState(null);
  const [ongoingChallenge, setOngoingChallenge] = useState(null);
  const [curTask, setCurTask] = useState(null);
  const [isOngoingChallengeSelected, setIsOngoingChallengeSelected] =
    useState(false);
  const currentDate = new Date().toISOString().split("T")[0];
  const { user } = useContext(AuthContext);

  useEffect(() => {
    const fetchChallenges = async () => {
      try {
        // Make both API calls using Promise.allSettled
        const [activeChallengesResp, enrolledChallengesResp] =
          await Promise.allSettled([
            apiClient.get("/api/challenges/upcoming"),
            apiClient.get(`/api/challenges/${user.id}`),
          ]);

        let upcomingChallenges = [];
        let enrolledChallenges = [];

        // Handle the first API call result
        if (activeChallengesResp.status === "fulfilled") {
          upcomingChallenges = activeChallengesResp.value.data;
        } else {
          message.error("Failed to fetch upcoming challenges.");
        }

        // Handle the second API call result
        if (enrolledChallengesResp.status === "fulfilled") {
          enrolledChallenges = enrolledChallengesResp.value.data;
        } else {
          if (
            enrolledChallengesResp.reason.response &&
            enrolledChallengesResp.reason.response.data.error ===
              "User is not enrolled in any challenges"
          ) {
            message.info("You haven't enrolled in any Challenge yet.");
          } else {
            message.error("Failed to fetch enrolled challenges.");
          }
        }

        // Continue with processing even if one of the APIs failed
        if (upcomingChallenges.length > 0 && enrolledChallenges.length > 0) {
          // Mark enrolled challenges
          _.forEach(upcomingChallenges, (item) => {
            if (_.some(enrolledChallenges, ["id", item.id])) {
              item.enrolled = true;
            }
          });

          // Find the active challenge
          const activeChallenge = _.find(enrolledChallenges, {
            status: "active",
          });
          if (activeChallenge) {
            setOngoingChallenge(activeChallenge);
            setCurTask(
              _.find(activeChallenge.tasks, { task_date: currentDate })
            );
          }
        }

        setUpcomingChallenges(upcomingChallenges);
      } catch (e) {
        message.error("An unexpected error occurred.");
      }
    };

    fetchChallenges();
  }, [user.id, currentDate]);

  const showDrawer = (challenge, isOngoing) => {
    setSelectedChallenge(challenge);
    setIsOngoingChallengeSelected(isOngoing);
    setIsDrawerVisible(true);
  };

  const closeDrawer = () => {
    setIsDrawerVisible(false);
    setSelectedChallenge(null);
  };

  const enrollChallenge = (challenge) => {
    let param = {
      userId: user.id,
      challengeId: challenge.id,
    };
    apiClient
      .post("api/challenges/enroll", param)
      .then((resp) => {
        setUpcomingChallenges(
          upcomingChallenges.map((activeChallenge, i) => {
            if (activeChallenge.id === challenge.id) {
              activeChallenge.enrolled = true;
            }
            return activeChallenge;
          })
        );
        message.info(
          "You are now enrolled in the challenge: " + challenge.name
        );
      })
      .catch((e) => {
        message.error("Error occurred while enrolling you in the challenge.");
      });
  };

  const withdrawChallenge = (challenge) => {
    let param = {
      userId: user.id,
      challengeId: challenge.id,
      enrollmentId: challenge.enrollmentId,
    };
    apiClient
      .post("api/challenges/disenroll", param)
      .then((resp) => {
        setUpcomingChallenges(
          upcomingChallenges.map((upcomingChallenge, i) => {
            if (upcomingChallenge.id === challenge.id) {
              upcomingChallenge.enrolled = false;
            }
            return upcomingChallenge;
          })
        );
        message.info(
          "You are no longer part of the challenge: " + challenge.name
        );
      })
      .catch((e) => {
        message.error("Error occurred while removing you from the challenge.");
      });
  };

  const markTaskAsComplete = () => {
    let param = {
      userId: user.id,
      challengeId: ongoingChallenge.id,
      taskId: curTask.id,
    };
    apiClient
      .post("api/challenges/complete", param)
      .then((resp) => {
        setCurTask({
          ...curTask,
          status: "completed",
        });
        message.info("Congrats! You finished today's task.");
      })
      .catch((e) => {
        message.error("Error happens when marking today's task as completed.");
      });
  };

  return (
    <Wrapper>
      <div style={{marginTop: "20px"}}>
        {ongoingChallenge && curTask ? (
          <OngoingChallenge
            ongoingChallenge={ongoingChallenge}
            curTask={curTask}
            onComplete={markTaskAsComplete}
            showDrawer={showDrawer}
          />
        ) : (
          <>
            <Title level={5} style={{ marginTop: "5px" }}>
              Ongoing Challenges
            </Title>
            <Divider style={{ marginTop: "5px" }} />
            <p style={{ textAlign: "center" }}>
              {" "}
              You don't have any Ongoing Challenges
            </p>
          </>
        )}
        <br />
        <UpcomingChallenges
          upcomingChallenges={upcomingChallenges}
          onEnroll={enrollChallenge}
          onWithdraw={withdrawChallenge}
          showDrawer={showDrawer}
        />
        {selectedChallenge && (
          <Tasks
            challenge={selectedChallenge}
            visible={isDrawerVisible}
            onClose={closeDrawer}
            isOngoing={isOngoingChallengeSelected}
          />
        )}
      </div>
    </Wrapper>
  );
};

export default Challenges;
