import React from "react";
import { Badge, Card, Button, Typography, Divider, Progress } from "antd";
import {
  CheckOutlined,
  TrophyOutlined,
  ClockCircleOutlined,
  CheckCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";

const { Title, Text } = Typography;

const twoColors = {
  '0%': '#ffccc7',
  '50%': '#ffe58f',
  '100%': '#87d068',
};

const formatDate = (date) => {
  const options = { day: "2-digit", month: "short", year: "numeric" };
  return new Date(date).toLocaleDateString("en-US", options);
};

const OngoingChallenge = ({
  ongoingChallenge,
  curTask,
  onComplete,
  showDrawer,
}) => (
  <>
    <Title level={5} style={{ marginTop: "5px" }}>
      Ongoing Challenges
    </Title>
    <Divider style={{ marginTop: "5px" }} />

    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      {/* Combined Ongoing Challenge Card */}
      <div
        style={{
          width: "100%",
          maxWidth: "800px",
          borderColor: "rgb(49, 112, 18)", // Green border color
          borderWidth: 1,
          borderStyle: "solid",
          borderRadius: "8px", // Rounded borders to match the card
          overflow: "hidden", // Ensures that rounded borders are applied
        }}
      >
        <Card
          title={
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>
                  {ongoingChallenge.name}
                </Title>
                <Text type="secondary">
                  {ongoingChallenge.start_date} - {ongoingChallenge.end_date}
                </Text>
              </div>
              <Button
                type="link"
                icon={<InfoCircleOutlined />}
                onClick={() => showDrawer(ongoingChallenge, true)}
              >
                Details
              </Button>
            </div>
          }
          bordered={false}
          style={{ height: "100%" }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "8px",
              alignItems: "center",
            }}
          >
            <p
              style={{
                flex: "1 1 auto",
                display: "flex",
                alignItems: "center",
                marginBottom: "0",
                marginTop: 0,
              }}
            >
              <ClockCircleOutlined
                style={{ marginRight: "8px", color: "purple" }}
              />
              {ongoingChallenge.duration} tasks in the challenge
            </p>
            <p
              style={{
                flex: "1 1 auto",
                display: "flex",
                alignItems: "center",
                marginBottom: "0",
                marginTop: 0,
              }}
            >
              <TrophyOutlined style={{ marginRight: "8px", color: "green" }} />
              Complete {ongoingChallenge.passCriteria} tasks to win the
              challenge
            </p>
            <div style={{ flex: "1 1 100%" }}>
              {" "}
              {/* Full width progress bar */}
              <Progress percent={99.9} size="small" strokeColor={twoColors} />
            </div>
          </div>

          {/* Today's Task inside the Ongoing Challenge Card */}
          <div
            style={{
              border: "2px solid #d9d9d9", // Light gray border around the inner card
              borderRadius: "8px",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Soft shadow for paper effect
              // padding: '16px',
              marginTop: "16px",
            }}
          >
            <Badge.Ribbon text="Today's Task" color="magenta">
              <Card
                hoverable={false}
                title={
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>
                      {curTask.name}
                    </Title>
                    <Text type="secondary">
                      {formatDate(curTask.task_date)}
                    </Text>
                  </div>
                }
                bordered={false}
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div style={{ flexGrow: 1, marginBottom: "16px" }}>
                  {curTask.desc}
                </div>
                <Button
                  type="primary"
                  icon={<CheckOutlined />}
                  disabled={curTask.status !== null}
                  onClick={onComplete}
                  style={{ width: "100%", marginTop: "auto" }}
                >
                  {curTask.status ? "Completed" : "Mark complete"}
                </Button>
              </Card>
            </Badge.Ribbon>
          </div>
        </Card>
      </div>
    </div>
  </>
);

export default OngoingChallenge;
