import React, { useRef, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../login/AuthContext";
import UserDetails from "../login/UserDetails";
import "./subnav.css";

const Subnav = ({ showMoodModal, showMusicPlayer }) => {
  const navbarRef = useRef(null);
  const [showLeftChevron, setShowLeftChevron] = useState(false);
  const [showRightChevron, setShowRightChevron] = useState(false);
  const { isAuthenticated, logout } = useContext(AuthContext);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  
  const navigate = useNavigate();

  // Retrieve user data from localStorage
  let user;
  let userInitials;
  if (isAuthenticated) {
    user = JSON.parse(localStorage.getItem("user"));
    userInitials = user?.first_name?.[0].toUpperCase() + user?.last_name?.[0].toUpperCase();
  }

  const handleNavItemClick = (key) => {
    if (key === "mood") {
      showMoodModal();
    } else if (key === "music" && isAuthenticated) {
      showMusicPlayer();
    }
  };

  const scrollNavbar = (distance) => {
    navbarRef.current.scrollBy({ left: distance, behavior: "smooth" });
  };

  const updateChevrons = () => {
    const navbar = navbarRef.current;
    const scrollLeft = navbar.scrollLeft;
    const maxScrollLeft = navbar.scrollWidth - navbar.clientWidth;

    setShowLeftChevron(scrollLeft > 0);
    setShowRightChevron(scrollLeft < maxScrollLeft);
  };

  useEffect(() => {
    const navbar = navbarRef.current;
    updateChevrons();
    navbar.addEventListener("scroll", updateChevrons);
    return () => {
      navbar.removeEventListener("scroll", updateChevrons);
    };
  }, []);

  const handleLogout = () => {
    logout(); // Perform the logout action
    navigate("/login"); // Redirect to login page after logout
    toggleDrawer(false);
  };

  const toggleDrawer = (open) => {
    setIsDrawerOpen(open);
  };

  return (
    <div className="navbar-container">
      {showLeftChevron && (
        <div
          className="chevron chevron-left"
          onClick={() => scrollNavbar(-200)}
        >
          &#9664;
        </div>
      )}
      <div className="navbar" ref={navbarRef}>
        {isAuthenticated ? (
          <>
            <div className="nav-item" onClick={() => navigate("")}>
              <span className="nav-item-icon">🏠</span>
              Home
            </div>
            <div className="nav-item" onClick={() => navigate("challenges")}>
              <span className="nav-item-icon">🏆</span>
              Challenges
            </div>
            {/* <div className="nav-item" onClick={() => navigate("articles")}>
              <span className="nav-item-icon">📄</span>
              Articles
            </div> */}
            <div className="nav-item" onClick={() => navigate("marketplace")}>
              <span className="nav-item-icon">🛒</span>
              Marketplace
            </div>
            {/* <div className="nav-item" onClick={() => navigate("tips")}>
              <span className="nav-item-icon">💡</span>
              Tips
            </div> */}
            <div className="nav-item" onClick={() => navigate("events")}>
              <span className="nav-item-icon">📅</span>
              Events
            </div>
            <div className="nav-item" onClick={() => navigate("peer-review")}>
              <span className="nav-item-icon">👥</span>
              Peer Review
            </div>
            <div
              className="nav-item"
              onClick={() => handleNavItemClick("mood")}
            >
              <span className="nav-item-icon">🌦️</span>
              Mood
            </div>
            <div className="nav-item" onClick={() => navigate("poll")}>
              <span className="nav-item-icon">🗳️</span>
              Poll
            </div>
            <div className="nav-item" onClick={() => navigate("videos")}>
              <span className="nav-item-icon">💿</span>
              Videos
            </div>
            <div
              className="nav-item"
              onClick={() => handleNavItemClick("music")}
            >
              <span className="nav-item-icon">🥁</span>
              Music
            </div>
            <div className="nav-item" onClick={() => navigate("games")}>
              <span className="nav-item-icon">🎲</span>
              Games
            </div>
            {/* <div className="nav-item" onClick={handleLogout}>
              <span className="nav-item-icon">🚪</span>
              Logout
            </div> */}
            <div className="nav-item" onClick={() => toggleDrawer(true)}>
              <span className="nav-item-icon">👤</span>
              {user.first_name}
            </div>
          </>
        ) : (
          <>
            {/* <div className="nav-item" onClick={() => navigate("/login")}>
              <span className="nav-item-icon">🔑</span>
              Login
            </div> */}
          </>
        )}
      </div>
      {showRightChevron && (
        <div
          className="chevron chevron-right"
          onClick={() => scrollNavbar(200)}
        >
          &#9654;
        </div>
      )}
      <UserDetails
        isDrawerOpen={isDrawerOpen}
        toggleDrawer={toggleDrawer}
        user={user}
        handleLogout={handleLogout}
      />
    </div>
  );
};

export default Subnav;
