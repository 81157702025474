import React, { useState } from "react";
import { Card, Avatar, Button, Typography, Space, Image } from "antd";
import { LikeOutlined, LikeFilled, CommentOutlined } from "@ant-design/icons";
import PostCommentsDrawer from "./PostCommentsDrawer";
import { getLocalTimeFromUTC4 } from "../../utils/DateUtils";

const { Paragraph } = Typography;

const PostCard = ({ post, handleLike }) => {
  const [drawerVisible, setDrawerVisible] = useState(false);

  const handleShowComments = () => {
    setDrawerVisible(true);
  };

  const handleCloseComments = () => {
    setDrawerVisible(false);
  };

  const getInitials = (name) => {
    if (!name || typeof name !== "string") return ""; // Return an empty string if name is undefined or not a string
    const nameParts = name.split(" ");
    if (nameParts.length >= 2) {
      return `${nameParts[0][0]}${nameParts[1][0]}`.toUpperCase();
    }
    return name[0] ? name[0].toUpperCase() : ""; // Return first letter, if exists
  };

  return (
    <Card
      id="home-post-card"
      style={{ marginBottom: 20, width: "100%", padding: 0 }}
      actions={[
        <Button
          type="link"
          icon={post.isUserLikedPost ? <LikeFilled /> : <LikeOutlined />}
          onClick={() => handleLike(post.postId)}
        >
          {post.likesCount} Likes
        </Button>,
        <Button
          type="link"
          icon={<CommentOutlined />}
          onClick={handleShowComments}
        >
          {post.commentsSize} Comments
        </Button>,
      ]}
    >
      <div style={{ paddingLeft: "24px", paddingRight: "24px" }}>
        <Card.Meta
          avatar={
            <Avatar
              src={post.avatar}
              size={44} // Set the avatar size to 44x44 pixels
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "44px",
                height: "44px",
                lineHeight: "44px", // Ensures the text initials are vertically centered
              }}
            >
              {!post.avatar && getInitials(post.userName)}
            </Avatar>
          }
          title={
            <Space
              direction="vertical"
              size={0}
              className="postUserNameAndTime"
            >
              <span>{post.userName}</span>
              <span style={{ color: "gray", fontSize: "11px" }}>
                {getLocalTimeFromUTC4(post.postedOn)}
              </span>
            </Space>
          }
        />

        <Paragraph style={{ marginTop: "15px" }} ellipsis={{ rows: 3 }}>
          {post.postContent}
        </Paragraph>
      </div>

      {post.media && post.media.length > 0 && (
        <Image.PreviewGroup>
          <div
            style={{
              display: "flex",
              gap: "10px",
              overflowX: "auto", // Enables horizontal scrolling
              whiteSpace: "nowrap", // Prevents wrapping of images
            }}
          >
            {post.media.map((image, index) => (
              <Image
                key={index}
                src={image}
                style={{
                  objectFit: "cover",
                  width: "250px", // Adjust the width of each image
                  height: "250px", // Set a fixed height
                }}
                alt={`Gallery Image ${index}`}
              />
            ))}
          </div>
        </Image.PreviewGroup>
      )}

      {/* Drawer for comments */}
      <PostCommentsDrawer
        visible={drawerVisible}
        onClose={handleCloseComments}
        comments={post.comments}
        postId={post.postId}
        title="Post Comments"
      />

      <style jsx="true">{`
        #home-post-card .ant-card-body {
          padding-left: 0px !important;
          padding-right: 0px !important;
          padding-bottom: 0px !important;
        }

        #home-post-card .postUserNameAndTime .ant-space-item:first-child {
          margin-bottom: -10px;
        }

        #home-post-card .ant-image-preview-mask {
          background-color: rgba(0, 0, 0, 0.9) !important;
        }
      `}</style>
    </Card>
  );
};

export default PostCard;
