import React, { useState } from "react";
import {
  Form,
  Input,
  Select,
  Button,
  InputNumber,
  DatePicker,
  Typography,
  AutoComplete,
  Spin,
  message
} from "antd";
import "./PeerAppreciationForm.css";
import useApiClient from "../../../apiClient";
import moment from "moment";
import useFetchUsers from "../../common/useFetchUsers";

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Text } = Typography;

const categories = [
  "Teamwork",
  "Innovation",
  "Leadership",
  "Customer Service",
  "Problem Solving",
  // Add more categories as needed
];

const ProvideFeedback = ({ reviewerId, onFormSubmitSuccess, closeDrawer }) => {
  const apiClient = useApiClient();
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { debouncedFetchUsers, options } = useFetchUsers();
  const [inputValue, setInputValue] = useState(""); // Input field value to display the name
  const [selectedId, setSelectedId] = useState("");

  // Handler when the user selects a recipient from the dropdown
  const handleSelect = (value, option) => {
    setSelectedId(value); // Set the selected ID (user's ID)
    setInputValue(option.label); // Set the input field to show the selected name

    form.setFieldsValue({ recipient: value });
  };

  // Handler for when user types in the input
  const handleSearch = (value) => {
    setInputValue(value); // Update the input field as the user types
    debouncedFetchUsers(value); // Fetch users from the backend
  };

  const onFinish = async (values) => {
    setLoading(true);
    const [reviewStartDate, reviewEndDate] = values.dateRange;

    const data = {
      reviewerId: reviewerId,
      revieweeId: values.recipient,
      reviewStartDate: reviewStartDate.format("YYYY-MM-DD"),
      reviewEndDate: reviewEndDate.format("YYYY-MM-DD"),
      title: values.title,
      description: values.purpose,
      category: values.category,
      pointsAwarded: values.pointsAwarded,
    };

    try {
      const response = await apiClient.post("/api/reviews/save", data);
      // setSubmissionStatus({
      //   type: "success",
      //   message: "Review submitted successfully",
      // });
      form.resetFields(); // Clear the form on successful submission
      message.success("Review submitted sucessfully");
      onFormSubmitSuccess(); // Trigger the callback to refresh card data
    } catch (error) {
      // setSubmissionStatus({
      //   type: "error",
      //   message: "Failed to submit review",
      // });
      message.success("Review submission failed");
    } finally {
      setLoading(false);
    }
  };

  // Function to disable dates before two months ago
  const disabledDate = (current) => {
    const twoMonthsAgo = moment().subtract(2, "months").startOf("day");
    const today = moment().endOf("day");
    return current && (current < twoMonthsAgo || current > today);
  };

  return (
    <div style={{ marginTop: "10px" }}>
      <Spin spinning={loading}>
      <Form
        form={form}
        onFinish={onFinish}
        className="provide-feedback-custom-form"
        layout="vertical"
        autoComplete="off"
      >
        <Form.Item
          label="Recipient's Name"
          name="recipient"
          rules={[
            { required: true, message: "Please enter the recipient's name" },
          ]}
        >
          <AutoComplete
            options={options} // Options from the custom hook
            onSearch={handleSearch} // Fetch users as user types
            onSelect={handleSelect} // Handle when a user is selected
            value={inputValue} // Display the selected name in the input field
            onChange={(value) => setInputValue(value)} // Update input field as user types
            placeholder="Enter recipient's name"
            filterOption={
              (inputValue, option) =>
                option.label.toLowerCase().includes(inputValue.toLowerCase()) // Match based on name
            }
          >
            <Input />
          </AutoComplete>
          <input type="hidden" name="recipient" value={selectedId} />
        </Form.Item>
        <Form.Item
          label="Review Period"
          name="dateRange"
          rules={[{ required: true, message: "Please select a date range" }]}
        >
          <RangePicker
            disabledDate={disabledDate}
            format="YYYY-MM-DD"
            style={{ width: "100%" }} // Set the RangePicker to 100% width
          />
        </Form.Item>
        <Form.Item
          label="Title"
          name="title"
          rules={[{ required: true, message: "Please enter a title" }]}
        >
          <Input placeholder="Enter review title" />
        </Form.Item>
        <Form.Item
          label="Purpose"
          name="purpose"
          rules={[
            {
              required: true,
              message: "Please enter the purpose of appreciation",
            },
          ]}
        >
          <Input.TextArea
            placeholder="Why are they being appreciated?"
            rows={4}
          />
        </Form.Item>
        <Form.Item
          label="Category"
          name="category"
          rules={[{ required: true, message: "Please select a category" }]}
        >
          <Select placeholder="Select a category">
            {categories.map((cat) => (
              <Option key={cat} value={cat}>
                {cat}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Points Awarded"
          name="pointsAwarded"
          rules={[{ required: true, message: "Please enter points awarded" }]}
        >
          <InputNumber
            min={0}
            max={100}
            placeholder="Enter points awarded"
            style={{ width: "100%" }}
          />
        </Form.Item>
        {submissionStatus && (
          <Text type={submissionStatus.type}>{submissionStatus.message}</Text>
        )}
        <Form.Item style={{ textAlign: "center", marginTop: "30px" }}>
          <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
            Submit
          </Button>
        </Form.Item>
      </Form>
      </Spin>

      {/* Custom CSS to remove asterisk for required fields */}
      <style jsx="true">{`
        .provide-feedback-custom-form .ant-form-item-required::before {
          display: none !important;
        }

        .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-body {
          padding-top: 0;
        }
      `}</style>
    </div>
  );
};

export default ProvideFeedback;
