import React from "react";
import { Drawer, Button, Typography } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { getUserTimezone } from "../../utils/DateUtils";

const { Text, Title } = Typography;

const UserDetails = ({ isDrawerOpen, toggleDrawer, user, handleLogout }) => {
  return (
    <Drawer
      title={
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <span>User Details</span> {/* Title on the left */}
          <Button type="link" onClick={() => toggleDrawer(false)}>Close</Button> {/* Close button on the right */}
        </div>
      }
      placement="right"
      closable={false}
      open={isDrawerOpen}
      onClose={() => toggleDrawer(false)}
      style={{ paddingTop: 0 }}
      footer={
        <div style={{ textAlign: "right", margin: "0px 20px 0px 20px"}}>
          <Button
            danger
            ghost
            icon={<LogoutOutlined />}
            onClick={handleLogout}
            style={{ marginTop: 20, marginBottom: 20, width: "100%" }}
          >
            Logout
          </Button>
        </div>
      }
      className="user-details-drawer"
    >
      <div className="dot-pattern">
        <Title level={5} style={{ marginBottom: 0 }}>
          First Name
        </Title>
        <Text>{user?.first_name}</Text>

        <Title level={5} style={{ marginBottom: 0 }}>
          Last Name
        </Title>
        <Text>{user?.last_name}</Text>

        <Title level={5} style={{ marginBottom: 0 }}>
          E-Mail
        </Title>
        <Text>{user?.email}</Text>

        <Title level={5} style={{ marginBottom: 0 }}>
          Time Zone
        </Title>
        <Text>{user? getUserTimezone() : '-'}</Text>
      </div>

      <style jsx="true">{`
        .user-details-drawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-body {
          padding-top: 0;
        }
      `}</style>
    </Drawer>
  );
};

export default UserDetails;
