import React, { useContext } from "react";
import { Drawer, Form, Button, Input, message } from "antd";
import useApiClient from "../../apiClient";
import { AuthContext } from "../login/AuthContext";

const CreatePoll = ({ visible, onClose, refreshPolls }) => {
  const [form] = Form.useForm();
  const apiClient = useApiClient();
  const { user } = useContext(AuthContext);

  const createPoll = async (values) => {
    if (values.options.length < 2) {
      message.error("You must enter at least two options!");
      return;
    }

    try {
      await apiClient.post("/api/polls/create", {
        question: values.question,
        options: values.options,
        userId: user.id, // Replace with actual user ID
      });
      form.resetFields();
      message.success("Poll created successfully.");
      onClose();
      refreshPolls();
    } catch (error) {
      message.error("Failed to create poll.");
    }
  };

  return (
    <Drawer
      title="Create a New Poll"
      width={400}
      visible={visible}
      onClose={onClose}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Form form={form} onFinish={createPoll} layout="vertical">
        <Form.Item
          name="question"
          label="Question"
          rules={[{ required: true, message: "Please enter the question!" }]}
        >
          <Input placeholder="Enter poll question" />
        </Form.Item>
        <Form.List
          name="options"
          rules={[
            {
              validator: async (_, options) => {
                if (!options || options.length < 2) {
                  return Promise.reject(
                    new Error("Please enter at least two options!")
                  );
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item
                  key={index}
                  {...field}
                  rules={[
                    { required: true, message: "Please enter an option!" },
                  ]}
                >
                  <Input placeholder={`Option ${index + 1}`} />
                </Form.Item>
              ))}
              {fields.length < 4 && (
                <Form.Item>
                  <Button default block onClick={() => add()}>
                    Add Option
                  </Button>
                </Form.Item>
              )}
            </>
          )}
        </Form.List>
        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            Create Poll
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default CreatePoll;
