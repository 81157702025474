import React, { useState, useEffect, useRef, useContext } from "react";
import { Drawer, List, Button, Input } from "antd";
import useApiClient from "../../apiClient";
import { PlayCircleTwoTone, PauseCircleTwoTone } from "@ant-design/icons";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { AuthContext } from "../login/AuthContext";
import "./AudioPlayer.css";
import { Spin } from "antd";

const { Search } = Input;

const AudioPlayerComponent = ({ isAudioPlayerVisible, onClose }) => {
  const { user } = useContext(AuthContext);
  const apiClient = useApiClient();
  const [currentTrackIndex, setCurrentTrackIndex] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [audioData, setAudioData] = useState([]);
  const [filteredAudioData, setFilteredAudioData] = useState([]);
  const audioPlayerRef = useRef(null);

  useEffect(() => {
    if (isAudioPlayerVisible) {
      const fetchAudioData = async () => {
        try {
          console.log("user ", user);
          const response = await apiClient.get(`/api/audios`);
          if (response.data) {
            setAudioData(response.data);
            setFilteredAudioData(response.data);
          }
          setLoading(false);
        } catch (err) {
          setLoading(false);
        }
      };

      if (audioData.length === 0) {
        fetchAudioData();
      }
    }
  }, [isAudioPlayerVisible]);

  const handlePlay = (index) => {
    if (currentTrackIndex === index) {
      // If the clicked song is already playing, toggle between play and pause
      if (isPlaying) {
        audioPlayerRef.current.audio.current.pause(); // Pause the song
        setIsPlaying(false);
      } else {
        audioPlayerRef.current.audio.current.play(); // Play the song
        setIsPlaying(true);
      }
    } else {
      // Switch to a new song
      setCurrentTrackIndex(index);
      setIsPlaying(true); // Start playing the new track
    }
  };

  const handlePause = () => {
    audioPlayerRef.current.audio.current.pause(); // Pause the current song
    setIsPlaying(false);
  };

  const handleNext = () => {
    setCurrentTrackIndex((prevIndex) => (prevIndex + 1) % audioData.length);
    setIsPlaying(true);
    audioPlayerRef.current.audio.current.play();
  };

  const handlePrevious = () => {
    setCurrentTrackIndex((prevIndex) =>
      prevIndex === 0 ? audioData.length - 1 : prevIndex - 1
    );
    setIsPlaying(true);
    audioPlayerRef.current.audio.current.play();
  };

  // Listen to play event from the AudioPlayer
  const onPlayerPlay = () => {
    setIsPlaying(true);
  };

  // Listen to pause event from the AudioPlayer
  const onPlayerPause = () => {
    setIsPlaying(false);
  };

  if (loading) {
    return (
      <div className="loading-container">
        <Spin tip="Loading audio..." />
      </div>
    );
  }

  const handleSearch = (query) => {
    setSearchQuery(query);

    if (query === "") {
      // Reset to full list if search is cleared
      setFilteredAudioData(audioData);
    } else {
      // Filter songs based on title or artist
      const filteredSongs = audioData.filter(
        (song) =>
          song.title.toLowerCase().includes(query.toLowerCase()) ||
          song.artist.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredAudioData(filteredSongs);
    }
  };

  const currentTrack = audioData ? audioData[currentTrackIndex] : null;

  return (
    <>
      <style jsx="true">{`
        #audio-player-drawer .ant-drawer-footer {
          padding: 0;
          background-color: rgb(244, 242, 238);
        }
      `}</style>

      <Drawer
        id="audio-player-drawer"
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <span>{"Audios"}</span>
            <Button type="link" onClick={onClose}>
              Close
            </Button>
          </div>
        }
        placement="right"
        closable={false}
        onClose={onClose}
        open={isAudioPlayerVisible}
        height={400}
        footer={
          <div style={{ padding: 0, margin: 0 }}>
            {currentTrack && (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingBottom: "10px",
                    marginTop: 10,
                  }}
                >
                  <img
                    src={currentTrack.thumbnail}
                    alt="album cover"
                    style={{
                      width: 50,
                      height: 50,
                      marginRight: 16,
                      marginLeft: 16,
                    }}
                  />
                  <div>
                    <div style={{ fontWeight: "bold", fontSize: 16 }}>
                      {currentTrack.title}
                    </div>
                    <div style={{ fontSize: 14 }}>{currentTrack.artist}</div>
                  </div>
                </div>
                <AudioPlayer
                  autoPlay={isPlaying} // Automatically play if the state is true
                  src={currentTrack.url}
                  showSkipControls={true}
                  showJumpControls={false}
                  onClickNext={handleNext}
                  onClickPrevious={handlePrevious}
                  ref={audioPlayerRef} // Attach the ref to control play/pause
                  onEnded={handleNext}
                  customVolumeControls={[]}
                  customAdditionalControls={[]}
                  autoPlayAfterSrcChange={true}
                  onPlay={onPlayerPlay} // Listen for play event
                  onPause={onPlayerPause} // Listen for pause event
                  className="dark-mode-player" // Custom dark mode class
                />
              </>
            )}
          </div>
        }
      >
        <Search
          placeholder="Search songs"
          value={searchQuery}
          onChange={(e) => handleSearch(e.target.value)}
          style={{ marginBottom: 16 }}
        />
        {filteredAudioData.length > 0 && (
          <List
            itemLayout="horizontal"
            dataSource={filteredAudioData}
            renderItem={(item, index) => (
              <List.Item
                onClick={() => handlePlay(index)}
                style={{ cursor: "pointer" }}
              >
                <List.Item.Meta
                  title={item.title}
                  description={item.artist}
                  avatar={
                    <img
                      src={item.thumbnail}
                      alt={item.title}
                      style={{ width: 50, height: 50 }}
                    />
                  }
                />

                {/* Only show play/pause icon next to the currently playing song */}
                {currentTrackIndex === index &&
                  (isPlaying ? (
                    <PauseCircleTwoTone
                      style={{ fontSize: "30px" }}
                      onClick={handlePause}
                    />
                  ) : (
                    <PlayCircleTwoTone
                      style={{ fontSize: "30px" }}
                      onClick={() => handlePlay(index)}
                    />
                  ))}
              </List.Item>
            )}
          />
        )}
      </Drawer>
    </>
  );
};

export default AudioPlayerComponent;
