import { useState } from "react";
import { debounce } from "lodash";
import { message } from "antd";
import useApiClient from "../../apiClient";

// Custom hook to fetch users from the backend
const useFetchUsers = () => {
  const apiClient = useApiClient();
  const [options, setOptions] = useState([]); // To store the fetched user names

  // Function to fetch users from the backend
  const fetchUsers = async (query) => {
    try {
      const response = await apiClient.get(`/api/users/search?name=${query}`);
      if (response.status === 200) {
        const users = response.data;
        const formattedOptions = users.map((user) => ({
          value: `${user.id}`,
          label: `${user.first_name} ${user.last_name} (${user.email})`,
        }));
        setOptions(formattedOptions);
      }
    } catch (error) {
      message.error("Failed to fetch users");
      console.error(error);
    }
  };

  // Debounce the fetchUsers function
  const debouncedFetchUsers = debounce((query) => {
    if (query.length >= 3) {
      fetchUsers(query);
    }
  }, 300); // Delay of 300ms

  // Return the debounced function and the options for the AutoComplete
  return { debouncedFetchUsers, options };
};

export default useFetchUsers;
