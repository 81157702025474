import React, { useState, useEffect, useContext } from "react";
import {
  Drawer,
  Empty,
  Form,
  Typography,
  Input,
  Button,
  message,
  Divider,
  List,
  Avatar,
} from "antd";
import { AuthContext } from "../login/AuthContext";
import useApiClient from "../../apiClient";
import { getLocalTimeFromUTC3 } from "../../utils/DateUtils";

const { Title, Text } = Typography;
const { TextArea } = Input;

const CommentsDrawer = ({
  visible,
  onClose,
  comments, // Default to empty array if comments is undefined
  fetchListings,
  listingId,
  title,
}) => {
  const apiClient = useApiClient();
  const { user } = useContext(AuthContext);
  const [newComment, setNewComment] = useState("");
  const [commentsList, setCommentsList] = useState(comments); // Ensure commentsList is initialized

  // Sync commentsList with comments prop when it changes
  useEffect(() => {
    setCommentsList(comments);
  }, [comments]);

  // Update the comment input
  const handleCommentChange = (e) => {
    setNewComment(e.target.value);
  };

  const getInitials = (name) => {
    return name
      .split(" ")
      .map((word) => word[0])
      .join("")
      .toUpperCase();
  };

  // Handle posting the new comment
  const handlePostComment = async () => {
    const userId = user.id;
    const userName = user.first_name + " " + user.last_name;

    try {
      const response = await apiClient.post("/api/marketplace/item/comment", {
        listingId: listingId,
        userId,
        userName,
        comment: newComment,
      });

      if (response.status === 201) {
        const newCommentData = response.data;
        message.success("Comment posted successfully!", 2);

        // Clear the comment input after posting
        setNewComment("");

        // Add the new comment to the top of the existing comments
        setCommentsList((prevComments) => [
          newCommentData,
          ...(prevComments || []),
        ]); // Ensure prevComments is an array

        // Optional: If you need to refresh the listing after the comment
        if (fetchListings) {
          fetchListings();
        }
      } else {
        message.error("Failed to post comment.", 2);
      }
    } catch (error) {
      console.error("Error posting comment:", error);
      message.error("An error occurred while posting the comment.", 2);
    }
  };

  return (
    <Drawer
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <span>{title}</span>
          <Button type="link" onClick={onClose}>
            Close
          </Button>
        </div>
      }
      placement="right"
      open={visible}
      width={400}
      onClose={onClose}
      closable={false}
      footer={
        <Form>
          <Form.Item>
            <TextArea
              rows={3}
              value={newComment}
              onChange={handleCommentChange}
              placeholder="Write your comment here..."
            />
          </Form.Item>
          <Form.Item style={{ textAlign: "center" }}>
            <Button
              type="primary"
              onClick={handlePostComment}
              disabled={!newComment.trim()}
              style={{ width: "100%" }}
            >
              Post Comment
            </Button>
          </Form.Item>
        </Form>
      }
    >
      <div style={{ marginTop: "-20px" }}>
        {commentsList && commentsList.length > 0 ? (
          <List
            itemLayout="horizontal"
            dataSource={commentsList}
            renderItem={(comment) => (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <Avatar
                      style={{
                        backgroundColor: "#888",
                        verticalAlign: "middle",
                      }}
                    >
                      {getInitials(comment.userName)}
                    </Avatar>
                  }
                  title={
                    <Text
                      style={{
                        marginBottom: 0,
                        display: "block",
                        fontWeight: 500,
                      }}
                    >
                      {comment.userName}
                    </Text>
                  }
                  description={
                    <>
                      <Text
                        type="secondary"
                        style={{
                          fontSize: "12px",
                          marginBottom: 10,
                          marginTop: 0,
                          display: "block",
                        }}
                      >
                        {getLocalTimeFromUTC3(comment.created)}
                      </Text>
                      <Text style={{ marginTop: 0 }}>{comment.comment}</Text>
                    </>
                  }
                />
              </List.Item>
            )}
          />
        ) : (
          <Empty description="No Comments" />
        )}
      </div>
    </Drawer>
  );
};

export default CommentsDrawer;
