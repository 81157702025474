import React from "react";
import { Drawer, Typography, Divider, Tag } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import moment from "moment"; // Assuming moment.js is used for date formatting

const { Text } = Typography;

const Tasks = ({ challenge, visible, onClose, isOngoing }) => {
  const currentDate = moment().format("YYYY-MM-DD"); // Assuming currentDate is in 'YYYY-MM-DD' format

  const showTasks = () => {
    return challenge.tasks.map((task, index) => {
      const formattedDate = moment(task.task_date).format("MMM DD, YYYY"); // Format date as Aug 30, 2024
      const isTaskCompleted = task.status === "completed";
      const isPastTask = currentDate > task.task_date;

      return (
        <div key={index} style={{ marginBottom: "16px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "8px",
            }}
          >
            <Text style={{ fontWeight: "bold", marginRight: "8px" }}>
              {formattedDate}
            </Text>
            <Tag
              color={isTaskCompleted ? "green" : isPastTask ? "red" : "blue"}
            >
              {isTaskCompleted
                ? "Completed"
                : isPastTask
                ? "Missed"
                : "Pending"}
            </Tag>
          </div>
          <Text>{task.name}</Text>

          <Divider style={{ margin: "10px 0" }} />
        </div>
      );
    });
  };

  return (
    <Drawer
      title={challenge.name} // Set the title based on the selected product
      placement="right"
      closable
      onClose={onClose}
      open={visible}
      width={window.innerWidth > 768 ? 400 : "100%"}
    >
      {showTasks()}
    </Drawer>
  );
};

export default Tasks;
