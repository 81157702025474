import React, { useState, useContext } from "react";
import { Button, Modal, Input, Upload, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import useApiClient from "../../apiClient";
import { AuthContext } from "../login/AuthContext";

const { TextArea } = Input;

const CreatePostModal = ({ isVisible, onClose }) => {
  const { user } = useContext(AuthContext);
  const [postContent, setPostContent] = useState("");
  const [fileList, setFileList] = useState([]); // For image uploads
  const apiClient = useApiClient();

  const handlePostContentChange = (e) => {
    setPostContent(e.target.value);
  };

  // Handle image upload, restrict to 5 images
  const handleImageUpload = ({ file, fileList }) => {
    if (fileList.length > 5) {
      message.error("You can only upload up to 5 images.");
      return;
    }
    setFileList(fileList);
  };

  const handleRemoveImage = (file) => {
    setFileList((prevList) => prevList.filter((item) => item.uid !== file.uid));
  };

  const handleCreatePost = async () => {
    const formData = new FormData();
    formData.append("userId", user.id);
    formData.append("content", postContent);

    // Append the images to the formData
    fileList.forEach((file) => {
      formData.append("images", file.originFileObj); // Send the actual file object
    });

    try {
      //   Call backend API to create post with text and images
      const response = await apiClient.post("/api/post/create", formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Necessary for file uploads
        },
      });

      if (response.status === 201) {
        message.success("Post created successfully!");
        onClose(); // Close modal after successful post creation
        setPostContent(""); // Reset post content
        setFileList([]); // Clear uploaded images
      } else {
        message.error("Failed to create post.");
      }
    } catch (error) {
      message.error("An error occurred while creating the post.");
    }
  };

  return (
    <>
      {/* Modal for creating a post */}
      <Modal
        title="Create Post"
        open={isVisible}
        onCancel={onClose}
        footer={[
          <Button key="cancel" onClick={onClose}>
            Cancel
          </Button>,
          <Button
            key="create"
            type="primary"
            onClick={handleCreatePost}
            disabled={!postContent.trim() && fileList.length === 0} // Disable if no content or images
          >
            Create Post
          </Button>,
        ]}
        centered
        closable={false}
      >
        {/* Text Area for Post Content */}
        <TextArea
          rows={3}
          value={postContent}
          onChange={handlePostContentChange}
          placeholder="Write your post here..."
          maxLength={160}
          style={{ marginBottom: "10px", marginTop: "10px" }}
        />

        {/* Image Upload Section */}
        <Upload
          accept="image/*" // Restrict to images only
          listType="picture-card" // Use thumbnail view for uploaded images
          fileList={fileList} // Current list of uploaded files
          onChange={handleImageUpload}
          onRemove={handleRemoveImage} // Allow users to remove images
          beforeUpload={() => false} // Prevent immediate upload (handled manually)
          multiple
        >
          {fileList.length >= 5 ? null : (
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          )}
        </Upload>

        {/* Instruction about image upload limit */}
        <p>You can upload up to 5 images.</p>
      </Modal>
    </>
  );
};

export default CreatePostModal;
