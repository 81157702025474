import React from "react";
import { Card, Space, Image, Typography, Row, Statistic, Tag, Popconfirm } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
  QuestionCircleOutlined,
  CommentOutlined,
} from "@ant-design/icons";
import "./Marketplace.css";

const { Title, Text } = Typography;

const ManageListingCard = ({ listing, onMarkSold, onRemove, onEdit, onComments }) => {
  const { pics, title, description, price, category, isAvailable, createdAt } =
    listing;

  const statusTag = isAvailable ? (
    <Tag color="green">Available</Tag>
  ) : (
    <Tag color="#f50">Sold</Tag>
  );

  return (
    <Card
      style={{ width: 300, borderRadius: 10}}
      cover={
        <Image
          src={pics[0]}
          alt={title}
          style={{
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            width: "100%",
            height: "250px",
            objectFit: "cover",
          }}
        />
      }
      actions={[
        // Popconfirm for "Mark Sold" action
        <Popconfirm
          title="Confirmation Needed"
          description="Are you sure you want to mark this listing as sold?"
          onConfirm={onMarkSold}
          okText="Yes"
          cancelText="No"
        >
          <CheckCircleOutlined key="Mark Sold" style={{ color: "green" }} />
        </Popconfirm>,

        // Popconfirm for "Delete" action
        <Popconfirm
          title="Delete Confirmation"
          description="Are you sure you want to delete this listing?"
          onConfirm={onRemove}
          okText="Yes"
          cancelText="No"
          icon={
            <QuestionCircleOutlined
              style={{
                color: 'red',
              }}
            />
          }
        >
          <DeleteOutlined key="delete" style={{ color: "red" }} />
        </Popconfirm>,

        <EditOutlined key="edit" onClick={onEdit} />,
        <CommentOutlined key="comments" onClick={onComments} />,
      ]}
      className={"manage-listing-card"}
    >
      <Space direction="vertical" size="small" style={{ width: "100%" }}>
        <Title
          level={4}
          style={{ marginTop: 0, marginBottom: 0, paddingBottom: 0 }}
        >
          {title}
        </Title>

        <Row
            justify="flex-start"
            align="middle"
            className="marketplace-listing-detail"
          >
            <Tag color="#108ee9">{category}</Tag>
            {statusTag}
          </Row>

          <Row
            justify="space-between"
            align="middle"
            className="marketplace-listing-detail"
          >
            {category === "Free" ? (
              <Statistic
                value={0}
                precision={0}
                prefix="$"
                valueStyle={{ color: "#9e8600" }}
              />
            ) : (
              <Statistic
                value={price}
                precision={2}
                prefix="$"
                valueStyle={{ color: "green" }}
              />
            )}
          </Row>

        <Text className="marketplace-description">{description}</Text>
      </Space>
    </Card>
  );
};

export default ManageListingCard;
