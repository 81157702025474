import React, { useState, useEffect, useContext } from "react";
import { Spin, Button, message, Card } from "antd";
import PostCard from "./PostCard";
import { AuthContext } from "../login/AuthContext";
import useApiClient from "../../apiClient";
import { ReloadOutlined, EditOutlined } from "@ant-design/icons";
import CreatePostModal from "./CreatePostModal";
import {
  getLocalTimeFromUTC,
  getUserLocalDateTime,
  getUserTimezone,
} from "../../utils/DateUtils";

const PAGE_SIZE = 10;

const Home = () => {
  const apiClient = useApiClient();
  const { user } = useContext(AuthContext);
  const [posts, setPosts] = useState([]);
  const [newPosts, setNewPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1); // Track the total number of pages
  const [commentsVisible, setCommentsVisible] = useState({});
  const [lastFetchedTime, setLastFetchedTime] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    //const now = new Date().toISOString(); // Track initial fetch time
    const now = getUserLocalDateTime();
    setLastFetchedTime(now);
    loadPosts(page);
  }, [page]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchNewPosts(); // Poll for new posts every 2 minutes
    }, 120000); // 2 minutes interval (120000 ms)

    return () => {
      clearInterval(interval); // Clean up the interval when component unmounts
    };
  }, [lastFetchedTime]);

  const loadPosts = async (page) => {
    try {
      setLoading(true);
      const response = await apiClient.get("/api/post/list", {
        params: {
          page: page,
          pageSize: PAGE_SIZE,
          userId: user.id, // Pass the current userId from context
        },
      });

      const newFetchedPosts = response.data.posts; // Assuming the API returns new posts in `response.data.posts`
      const totalPages = response.data.totalPages;

      setTotalPages(totalPages);
      setPosts((prevPosts) => {
        // Make sure to avoid duplicates if needed
        const allPosts = [...prevPosts, ...newFetchedPosts];

        // Optionally, remove duplicates by checking the unique post IDs
        const uniquePosts = allPosts.reduce((acc, post) => {
          if (!acc.find((p) => p.postId === post.postId)) {
            acc.push(post);
          }
          return acc;
        }, []);

        return uniquePosts;
      });
    } catch (error) {
      console.error("Error loading posts:", error);
      message.error("An error occurred while loading posts.");
    } finally {
      setLoading(false);
    }
  };

  const fetchNewPosts = async () => {
    try {
      const userId = user.id; // Assuming user information is available in context

      // Make the GET request to fetch new posts from the backend
      const response = await apiClient.get("/api/post/fetchNew", {
        params: {
          lastFetchedTime: lastFetchedTime, // Use the last fetched time as a query parameter
          userId: userId,
          timezone: getUserTimezone(),
        },
      });

      const newFetchedPosts = response.data; // Assuming backend returns an array of new posts

      if (newFetchedPosts.length > 0) {
        // Prepend new posts to the existing posts
        setPosts((prevPosts) => [...newFetchedPosts, ...prevPosts]);

        // Prepend new posts to the existing list
        setNewPosts((prevNewPosts) => [...newFetchedPosts, ...prevNewPosts]);

        // Find the latest postedOn value (most recent post)
        const latestPostTime = getLocalTimeFromUTC(newFetchedPosts[0].postedOn);
        setLastFetchedTime(latestPostTime);
      } else {
        console.log("No new posts available.");
      }
    } catch (error) {
      console.error("Error fetching new posts:", error);
      message.error("An error occurred while fetching new posts.");
    }
  };

  const handleScroll = () => {
    // Load the next page if not loading and if we haven't reached the last page
    if (
      window.innerHeight + window.scrollY >=
        document.body.offsetHeight * 0.75 &&
      !loading &&
      page < totalPages
    ) {
      setPage((prevPage) => prevPage + 1);
      loadPosts(page + 1);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loading, page, totalPages]);

  const handleLike = async (postId) => {
    try {
      const userId = user.id; // Assuming user information is available in context

      // Make the POST request to the backend to like/unlike the post
      const response = await apiClient.post("/api/post/like", {
        postId,
        userId,
      });

      if (response.status === 200) {
        // Update the local state only if the backend call is successful
        setPosts((prevData) =>
          prevData.map((post) =>
            post.postId === postId
              ? {
                  ...post,
                  likesCount: post.isUserLikedPost
                    ? post.likesCount - 1
                    : post.likesCount + 1,
                  isUserLikedPost: !post.isUserLikedPost,
                }
              : post
          )
        );
      } else {
        message.error("Failed to like/unlike the post.");
      }
    } catch (error) {
      console.error("Error liking/unliking the post:", error);
      message.error("An error occurred while liking/unliking the post.");
    }
  };

  const showComments = (postId) => {
    setCommentsVisible({ ...commentsVisible, [postId]: true });
  };

  const closeComments = (postId) => {
    setCommentsVisible({ ...commentsVisible, [postId]: false });
  };

  const handleNewPosts = () => {
    setNewPosts([]);
    // Scroll to the top
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const refreshPosts = () => {
    setPosts([]); // Clear the existing posts
    setPage(1); // Reset the page to 1
    loadPosts(1); // Call loadPosts to fetch the first page
  };

  const handleOpenCreatePostModal = () => {
    setIsModalVisible(true); // Show the modal
  };

  const handleCloseCreatePostModal = () => {
    setIsModalVisible(false); // Close the modal
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", padding: "20px", marginTop: "60px" }}>
      <div style={{ flexBasis: "10%" }}></div>
      <div style={{ flexBasis: "22.5%" }}></div>
      <div
        style={{
          flexBasis: "35%",
          maxWidth: "35%", // Ensure it doesn't grow beyond 35%
          overflowX: "hidden", // Hide any horizontal overflow
          boxSizing: "border-box",
        }}
      >
        {/* Show centered regular button when there are new posts */}
        {newPosts.length > 0 && (
          <div
            style={{
              position: "fixed",
              bottom: "20px",
              left: "50%",
              transform: "translateX(-50%)",
              zIndex: 1000,
            }}
          >
            <Button
              type="default"
              style={{ borderColor: "#4096ff" }}
              onClick={handleNewPosts}
            >
              New Posts
            </Button>
          </div>
        )}
        {posts.map((post) => (
          <PostCard
            key={post.postId}
            post={post}
            handleLike={handleLike}
            showComments={showComments}
            closeComments={closeComments}
            commentsVisible={commentsVisible}
          />
        ))}
        {loading && <Spin />}
      </div>
      <div style={{ flexBasis: "22.5%", alignItems: "flex-start", display: "flex", justifyContent: "center", alignContent: "flex-start" }}>
        <Card style={{ width: "90%", alignItems: "flex-start", justifyContent: "flex-start", display: "flex" }}>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            {/* Refresh Posts Button */}
            <Button
              type="link"
              icon={<ReloadOutlined />}
              onClick={() => refreshPosts()}
            >
              Refresh Posts
            </Button>
            {/* Create Post Button */}
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={handleOpenCreatePostModal}
            >
              Create a Post
            </Button>
          </div>
        </Card>
      </div>
      <div style={{ flexBasis: "10%" }}></div>

      {/* Floating Create Post button and modal */}
      <CreatePostModal
        isVisible={isModalVisible}
        onClose={handleCloseCreatePostModal}
      />
    </div>
  );
};

export default Home;
